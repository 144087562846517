@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
.login-layout {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.body--modal-open {
  overflow-y: hidden;
}

.container-flex {
  display: flex;
}
.container-flex.rvc {
  align-items: center;
}
.container-flex.rhc {
  justify-content: center;
}
.container-flex.cvc {
  align-content: center;
}
.container-flex.chc {
  justify-items: center;
}

@media (max-width: 1570px) {
  .profilePicture-container {
    width: 14rem !important;
    height: 14rem !important;
  }
  .allProfileContainer {
    flex-direction: column;
    align-items: center !important;
  }
  .informationsComptes {
    margin-top: 2rem !important;
  }
  .monCompte-leftSection {
    margin-right: 0 !important;
  }
  .container-basic.responsive {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.container-basic .header-input, .container-basic .header-select, .container-basic .searchDate-select {
  border: 1px solid #1E1E1E !important;
  margin-right: 0.8rem;
  border-radius: 0.5rem;
}
.container-basic .default-button {
  padding: 0.8rem 2rem;
}
.container-basic .h1-blue-dark {
  margin-bottom: 0.4rem;
}
.container-basic .header-search::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 2rem;
  width: 2rem;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  cursor: pointer;
}

.BanqueHeureAdmin-Header, .releveConsultation-top, .profilSalarie-Header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.BanqueHeureAdmin-Header .banqueButtonContainer, .BanqueHeureAdmin-Header .validerButtonContainer, .releveConsultation-top .banqueButtonContainer, .releveConsultation-top .validerButtonContainer, .profilSalarie-Header .banqueButtonContainer, .profilSalarie-Header .validerButtonContainer {
  display: flex;
}

.profilSalarie-Header {
  align-items: stretch;
}

.dashboard-layout {
  display: flex;
  width: 100%;
  height: 100%;
}
.dashboard-layout-main-container {
  transition: all 450ms ease;
  flex: 1;
  margin-left: 29rem;
  position: relative;
  min-height: 100vh;
}
.dashboard-layout-main-container.small_sidebar {
  margin-left: 7rem !important;
}
.dashboard-layout-main-container.small_sidebar .dashboard-header {
  width: calc(100% - 7rem) !important;
}
.dashboard-layout-main-container.openHover {
  margin-left: 29rem !important;
}
.dashboard-layout-main-container.openHover .dashboard-header {
  width: calc(100% - 29rem) !important;
}
.dashboard-layout .dashboard-header {
  transition: all 450ms ease;
}
.dashboard-layout-page-content {
  margin-top: 8.9rem;
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: center;
}
.dashboard-layout .footer-dashboard {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top: 1px solid #e5e9f2;
  padding: 2rem 0.4rem;
  box-shadow: 0px 1px 3px 0px rgba(54, 74, 99, 0.05);
}

.exportSection .checkboxExport {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 400;
  color: #3c4d62;
}
.exportSection .checkboxExport .login-checkbox {
  transform: scale(1.1);
  margin-right: 0.4rem;
  cursor: pointer;
}
.exportSection .checkboxExport .checkbox-label {
  cursor: pointer;
}
.exportSection .excelCard {
  width: 100%;
  min-width: 25rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.exportSection .excelCard .imgContainer {
  min-width: 100%;
  height: 40rem;
  background: url(https://www.distanciel.estc.fr/wp-content/uploads/2020/10/microsoft-excel-logo-1.png) no-repeat center center;
  background-size: cover;
}
.exportSection .excelCard .downloadExportButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.exportSection .excelCard .downloadOptions {
  padding: 1.6rem;
}
.exportSection .excelCard .downloadOptions .downloadLine {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.4rem;
}
.exportSection .excelCard .downloadOptions .downloadLine label {
  padding-bottom: 0;
  margin-right: 0.8rem;
}

.downloadLine {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.4rem;
}
.downloadLine label {
  padding-bottom: 0;
  margin-right: 0.8rem;
}

.vacationEnCours,
.vacationEnAttente,
.vacationTerminer {
  margin-bottom: 2rem;
}
.vacationEnCours hr,
.vacationEnAttente hr,
.vacationTerminer hr {
  margin-bottom: 1.6rem;
}
.vacationEnCours:last-child,
.vacationEnAttente:last-child,
.vacationTerminer:last-child {
  margin-bottom: 0;
}
.vacationEnCours .container-basic:last-child,
.vacationEnAttente .container-basic:last-child,
.vacationTerminer .container-basic:last-child {
  margin-bottom: 0;
}

.chef-dashboard-layout {
  background: #F7F6F9;
}
.chef-dashboard-layout .chef-dashboard-main {
  background: #F7F6F9;
  padding: 4.8rem;
}

.ouvrier-dashboard-layout .ouvrier-dashboard-main {
  padding: 4.8rem;
}

.monCompte-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.monCompte-container .userIcon {
  width: 100%;
  height: 100%;
  color: #d8556b;
}
.monCompte-container .allProfileContainer {
  margin-top: 2rem;
  display: flex;
  align-items: flex-start;
  flex: 1;
}
.monCompte-container .monCompte-leftSection {
  margin-right: 8rem;
}
.monCompte-container .profilePicture-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18rem;
  height: 18rem;
  border-radius: 0.5rem;
  border: 1px solid #dbdfea;
}
.monCompte-container .informationsComptes-labelInput-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  flex-wrap: wrap;
}
.monCompte-container .informationsComptes-labelInput-container p {
  margin-left: 20rem;
}
.monCompte-container .informationsComptes-labelInput-container label {
  display: inline-block;
  width: 20rem;
}
.monCompte-container .informationsComptes-labelInput-container input {
  flex: 1;
  margin-right: 0;
}
.monCompte-container .default-button {
  width: 100%;
  margin-top: 0.8rem;
}
.monCompte-container .monCompte-rightSection {
  flex: 1;
}
.monCompte-container .modifierMotdDePasse-iconAndText {
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
}
.monCompte-container .modifierMotdDePasse-iconAndText svg {
  width: 2rem;
  height: 2rem;
  margin-left: 0.4rem;
  color: #1E1E1E;
  transition: color 0.15s ease-in-out;
}
.monCompte-container .modifierMotdDePasse-iconAndText svg:hover {
  color: #0a0a0a;
}

.motDePasse-iconAndText {
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
}
.motDePasse-iconAndText svg {
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 0.4rem;
  color: #1E1E1E;
  transition: color 0.15s ease-in-out;
}
.motDePasse-iconAndText svg:hover {
  color: #0a0a0a;
}

.main-content-padding {
  padding: 2rem;
}

.headerCategorie {
  display: flex;
  margin-bottom: 1.6rem;
}
.headerCategorie-label {
  font-size: 1.5rem;
  color: #1E1E1E;
  font-weight: bold;
  flex: 1;
}

.categorieContainer {
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 1.6rem;
  background-color: #FFFFFF !important;
  position: relative !important;
  transition: background-color 0.3s, box-shadow 0.3s;
}
.categorieContainer::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #ffffff !important;
  transition: all 0.2s ease-in-out;
}
.categorieContainer:hover {
  box-shadow: none !important;
  transform: initial !important;
  transition: background-color 0.3s;
  background-color: #F6F6F6 !important;
  color: #1E1E1E;
}
.categorieContainer:hover::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #D13852 !important;
}
.categorieContainer.open {
  box-shadow: none !important;
  transform: initial !important;
  transition: background-color 0.3s;
  background-color: #F6F6F6 !important;
  color: #1E1E1E;
}
.categorieContainer.open::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #D13852 !important;
}
.categorieContainer p {
  font-size: 1.3rem;
  color: #1E1E1E;
  flex: 1;
}
.categorieContainer .table-buton-container {
  flex: 1;
}

.chevronCategorie {
  color: #1E1E1E;
  width: 2rem;
  height: 2rem;
  transition: transform 0.3s ease-in-out;
}
.chevronCategorie.open {
  transform: rotate(90deg);
}

.typeDocuments-container {
  background-color: #f5f5f5;
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  margin: 1.6rem;
}

.typeDocuments-item {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  border-bottom: 1px solid #ddd;
}
.typeDocuments-item:first-child {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.typeDocuments-item:last-child {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom: 0;
}
.typeDocuments-item p {
  font-size: 1.2rem;
}
.typeDocuments-item svg {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 2rem;
  cursor: pointer;
}
.typeDocuments-item svg:last-child {
  margin-right: 0;
}
.typeDocuments-item input {
  border: 1px solid #ddd;
  flex: 1;
}

.progressBar {
  flex: 1;
  margin-right: 1.6rem;
}
.progressBar .barCompleted {
  background-color: lightblue;
}

.fileList {
  margin-bottom: 2rem;
}

.pdfIcon {
  color: #d13852;
}

.lockIcon {
  color: #fff;
}

.privacyPolicyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding: 8rem;
}
.privacyPolicyContainer h1 {
  color: #1E1E1E;
  font-size: 2.8rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 8rem;
}
.privacyPolicyContainer .privacyPolicySection {
  width: 60vw;
  margin-bottom: 4.8rem;
}
.privacyPolicyContainer .privacyPolicySection p {
  color: #1E1E1E;
  font-size: 1.7rem;
  font-weight: 400;
}
.privacyPolicyContainer .privacyPolicySection p a {
  color: #8094ae;
  font-weight: 700;
  border-bottom: 0;
  transition: border-bottom 0.05s ease-in-out;
}
.privacyPolicyContainer .privacyPolicySection p a:hover {
  border-bottom: 1px solid #8094ae;
}
.privacyPolicyContainer .privacyPolicySection h2 {
  color: #1E1E1E;
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.privacyPolicyContainer .privacyPolicySection:last-child {
  margin-bottom: 0;
}

.gestion-documents-wrapper {
  margin-bottom: 4.8rem;
}

.checkbox-form {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 400;
  color: #3c4d62;
}
.checkbox-form .login-checkbox {
  transform: scale(1.1);
  margin-right: 0.4rem;
  cursor: pointer;
}
.checkbox-form .checkbox-label {
  cursor: pointer;
}

.vacation-infos-container {
  width: 45%;
}

.mediumWidth {
  width: 50%;
}

.signature {
  max-width: 100%;
  display: block;
  border: 1px solid #1e1e1e;
  border-radius: 0.5rem;
  margin: 0 auto;
}

.formationCard {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 0.8rem;
  padding-bottom: 2rem;
}

.currentFormation-container {
  flex: 1;
}
.currentFormation-container h4 {
  margin-bottom: 1.2rem;
}
.currentFormation-container .currentFormation {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}
.currentFormation-container .currentFormation .labelFormation {
  color: rgba(30, 30, 30, 0.5019607843);
}
.currentFormation-container .currentFormation .currentFormation-section {
  flex: 1;
}
.currentFormation-container .currentFormation .currentFormation-section p {
  line-height: 1.6;
}
.currentFormation-container .currentFormation .currentFormation-section p .bold {
  font-weight: 600;
}
.currentFormation-container .currentFormation .currentFormation-section:first-child {
  margin-right: 2rem;
}
.currentFormation-container hr {
  height: auto;
  width: 1px;
  background-color: #d9d9d9;
  color: #d9d9d9;
}

.formation-valide {
  border-inline-start: 5px solid #2fc451 !important;
}

.formation-expired {
  border-inline-start: 5px solid #d8556b !important;
}

.formation-urgent {
  border-inline-start: 5px solid #deb348 !important;
}

.documentFormation, .tableFileLink {
  cursor: pointer;
  transition: color 0.2s ease;
  width: -moz-fit-content;
  width: fit-content;
}
.documentFormation:hover, .tableFileLink:hover {
  color: #d8556b;
  border-bottom: 1px solid #d8556b;
  margin-bottom: -1px;
}
.documentFormation svg, .tableFileLink svg {
  width: 1.8rem;
  height: 1.8rem;
}

.tableFormationDocument svg {
  width: 1.8rem;
  height: 1.8rem;
}

.historiqueFormation {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.2s ease;
}
.historiqueFormation:hover {
  color: #d8556b;
}
.historiqueFormation h5 {
  margin-right: 0.4rem;
}
.historiqueFormation svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: -0.1rem;
  transition: transform 0.2s ease;
}
.historiqueFormation.opened svg {
  transform: rotate(90deg);
}

.historiqueFormation-tableContainer {
  transition: height 0.2s ease;
  overflow: hidden;
}

.addImageContainer {
  position: relative;
  width: 50%;
}
.addImageContainer .articleImage {
  width: 100%;
  height: auto;
}
.addImageContainer .imageDeleteOverlay {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  transition: all 0.3s ease;
  cursor: pointer;
}
.addImageContainer .imageDeleteOverlay:hover {
  opacity: 1;
}
.addImageContainer .btn_image {
  background-color: #555;
  color: white;
  padding: 14px;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}
.addImageContainer .btn_image:hover {
  background-color: #d8556b;
}
.addImageContainer .btn_image svg {
  width: 2rem;
  height: 2rem;
}

.actualite-container {
  background-color: white;
  border-radius: 0.6rem;
  box-shadow: 0 1px 2px 0 rgba(25, 15, 15, 0.07);
  border: 1px solid #f0f0f0;
  flex: 0.3333%;
  min-width: 310px;
  cursor: pointer;
}
.actualite-container:hover .actualiteImage {
  transform: scale(1.1);
}
.actualite-container:hover .readArticle {
  color: #d13852;
}
.actualite-container .actualiteImage {
  transition: all 0.3s ease;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.actualite-container .readArticle {
  color: #d8556b;
  transition: all 0.2s ease;
}

.voirArticleImage {
  width: 100%;
  max-height: 630px;
  -o-object-fit: scale-down;
     object-fit: scale-down;
  cursor: pointer;
  transition: all 0.2s ease;
}
.voirArticleImage:hover {
  transform: scale(1.2);
}

.articleContent {
  margin-top: 2.4rem;
}
.articleContent h3 {
  margin-bottom: 2.4rem;
}
.articleContent p {
  font-size: 1.6rem;
}

.accountSettings-profilePicture-wrapper {
  display: flex;
  justify-content: center;
}

.profilePictureDefault svg {
  width: 12rem;
  height: 12rem;
  color: #d8556b;
  z-index: 1;
}
.profilePictureDefault.accountSettings {
  width: 12rem;
  height: 12rem;
}
.profilePictureDefault.salarieProfil svg {
  width: 9.6rem;
  height: 9.6rem;
}
.profilePictureDefault.dashboardHeader svg {
  width: 2.3rem;
  height: 2.3rem;
}
.profilePictureDefault.table svg {
  width: 3.2rem;
  height: 3.2rem;
}
.profilePictureDefault.userlist svg {
  width: 4rem;
  height: 4rem;
}
.profilePictureDefault.form svg {
  width: 4rem;
  height: 4rem;
}

.profileName {
  font-size: 1.6rem;
  font-weight: 600;
}

.editProfilePicture-penContainer svg {
  width: 2rem;
  height: 2rem;
}

.imageHoverWithButton {
  -webkit-backdrop-filter: blur(2px) opacity(0);
          backdrop-filter: blur(2px) opacity(0);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out 0.2s;
}
.imageHoverWithButton div {
  transition: all ease-in-out 0.2s;
  opacity: 0;
}
.imageHoverWithButton div:hover {
  background-color: #d13852;
}
.imageHoverWithButton:hover {
  -webkit-backdrop-filter: blur(1px) opacity(1);
          backdrop-filter: blur(1px) opacity(1);
}
.imageHoverWithButton:hover div {
  opacity: 1;
}

.smallRoundButton {
  width: 40px;
  height: 40px;
  z-index: 3;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
}
.smallRoundButton svg {
  width: 2rem;
  height: 2rem;
}

.modal,
.modalSmall {
  z-index: 40;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(30, 30, 30, 0);
  top: 0;
  left: 0;
  transition: background 0.15s linear;
  display: none;
}
.modal__active,
.modalSmall__active {
  display: flex;
  justify-content: flex-end;
}
.modal__fade,
.modalSmall__fade {
  background: rgba(30, 30, 30, 0.4);
}
.modal__fade .modal-content,
.modalSmall__fade .modal-content {
  transform: translateX(0%) !important;
}

.modal .modal-content {
  background-color: #fff;
  border-radius: 0.5rem;
  position: relative;
  width: 45%;
  padding: 0 3.2rem;
  padding-top: 1.6rem;
  box-shadow: 0rem 0rem 0.1rem 0rem rgba(82, 100, 132, 0.2), 0rem 0.8rem 1.552rem 0.048rem rgba(28, 43, 70, 0.15) !important;
  height: 100%;
  transition: all 0.2s ease-in-out;
  transform: translateX(100%);
}

.modalSmall .modal-content {
  background-color: #fff;
  border-radius: 0.5rem;
  position: relative;
  padding: 1.6rem;
  width: 35%;
  box-shadow: 0rem 0rem 0.1rem 0rem rgba(82, 100, 132, 0.2), 0rem 0.8rem 1.552rem 0.048rem rgba(28, 43, 70, 0.15) !important;
  height: 100%;
  transform: translateX(100%);
  transition: all 0.2s ease-in-out;
}

.modal-open {
  overflow: hidden;
}

.new-modal-title {
  color: #1E1E1E;
  margin-bottom: 2rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #000;
}

.modal-close-button {
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  position: absolute;
  top: 1.6rem;
  right: 3.2rem;
  border: 1px solid transparent;
  background-color: transparent;
  color: #1E1E1E;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.modal-close-button:hover {
  color: #2b2b2b;
}
.modal-close-button:active {
  color: #d8556b;
}
.modal-close-button .close-icon {
  width: 95%;
  height: 95%;
}

.last-form-container {
  flex: 1;
}

.new-modal {
  background-color: #fff;
  border-radius: 0.5rem;
  position: relative;
  width: 45%;
  box-shadow: 0rem 0rem 0.1rem 0rem rgba(82, 100, 132, 0.2), 0rem 0.8rem 1.552rem 0.048rem rgba(28, 43, 70, 0.15) !important;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.new-modal .new-modal-topSection {
  margin-top: 1.6rem;
  margin-left: 3.2rem;
  margin-right: 3.2rem;
  margin-bottom: 2.4rem;
}
.new-modal .new-modal-mainSection {
  margin-left: 3.2rem;
  margin-right: 3.2rem;
  margin-bottom: 1.6rem;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.new-modal .new-modal-mainSection .fh-form {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.new-modal .new-modal-mainSection .fh-form .submit-section {
  flex: 1;
  display: flex;
  align-items: end;
}

.vertical-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: auto;
  padding-bottom: 2rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0rem 0.3rem 0.8rem;
}
.vertical-form .last-input {
  flex: 1;
}
.vertical-form.scrollable {
  overflow-y: auto;
}
.vertical-form.scrollable::-webkit-scrollbar-thumb {
  border-left: 10px white solid;
  background-clip: padding-box;
}
.vertical-form .default-input {
  margin-right: 0;
  margin-bottom: 0.8rem;
}
.vertical-form .default-select {
  margin-right: 0;
}
.vertical-form .react-datepicker-wrapper {
  width: 100% !important;
}
.vertical-form .react-datepicker-wrapper input {
  padding: 0.4rem 1.6rem;
  height: 4rem !important;
  margin-bottom: 0.8rem;
  border-radius: 5px;
  font-size: 1.5rem;
  border: 1px solid #dbdfea;
  width: 100% !important;
  text-align: center;
}
.vertical-form .react-datepicker-wrapper button {
  height: 4rem !important;
}
.vertical-form .react-daterange-picker, .vertical-form .react-timerange-picker {
  margin-bottom: 0.8rem;
}

.modal-content .vertical-form {
  border-radius: 0;
  box-shadow: none;
}
.modal-content .vertical-form.scrollable {
  padding-left: 0.08rem !important;
  padding-right: 0.8rem !important;
}

.mdpInput-button-container {
  display: flex;
  align-items: flex-start;
}
.mdpInput-button-container input {
  flex: 1;
  margin-right: 0.8rem;
}

.horizontal-form {
  display: flex;
  justify-content: space-between;
  height: 6.4rem;
}
.horizontal-form .select-search {
  width: auto;
  flex: 2;
  margin: 0;
  height: auto;
}
.horizontal-form button {
  margin: auto 0;
  width: 5rem;
}

.validerSemaine-form {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.1rem 0.3rem 0rem rgba(54, 74, 99, 0.05);
  position: relative;
  padding: 1.6rem;
}
.validerSemaine-form .modal-title {
  margin-bottom: 1.6rem;
}
.validerSemaine-form .modal-confirmation-text {
  margin-top: 1.6rem;
}
.validerSemaine-form .textAndResume-container {
  margin-bottom: 4.8rem;
}
.validerSemaine-form .suppression-mot {
  font-weight: 700;
  color: #d8556b;
}
.validerSemaine-form .button-container {
  display: flex;
  justify-content: center;
}
.validerSemaine-form .button-container .supprimer-form-annuler {
  margin-right: 4.8rem;
  width: 10rem;
  color: white !important;
  background-color: #d8556b;
  border-color: #d8556b;
}
.validerSemaine-form .button-container .supprimer-form-annuler:hover {
  background-color: #d13852;
}
.validerSemaine-form .button-container .supprimer-form-annuler:active {
  outline: 0.3rem solid #f3c9d0;
}
.validerSemaine-form .button-container .supprimer-form-valider {
  width: 10rem;
  color: white !important;
  background-color: #2fc451;
  border-color: #2fc451;
}
.validerSemaine-form .button-container .supprimer-form-valider:hover {
  background-color: #28a745;
}
.validerSemaine-form .button-container .supprimer-form-valider:active {
  outline: 0.3rem solid #d8f6df;
}

.default-form {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.1rem 0.3rem 0rem rgba(54, 74, 99, 0.05);
  position: relative;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
}
.default-form .modal-title {
  margin-bottom: 4.8rem;
  margin-right: 8rem;
}
.default-form-label {
  margin-bottom: 0.4rem;
}
.default-form-input {
  margin-bottom: 0.8rem;
}
.default-form .validate-button-fw {
  margin-top: 4.8rem;
}

.horizontal-form-fields-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.horizontal-form-fields-container .label-and-input:not(:last-child) {
  margin-right: 1.2rem;
}

.personnelList .personnelItem {
  display: flex;
  align-items: center;
}
.personnelList .personnelItem .userlist-formation-valide {
  color: #1E1E1E;
}
.personnelList .personnelItem .userlist-formation-expired {
  color: #d8556b;
}
.personnelList .personnelItem .userlist-formation-urgent {
  color: #deb348;
}
.personnelList .personnelItem:not(:last-child) {
  margin-bottom: 0.8rem;
}
.personnelList .salarieBasicInfo {
  width: 40%;
}
.personnelList .salarieFormations {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
}
.personnelList .salarieFormations.selectable {
  width: 40%;
}
.personnelList .editBox-container {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.salarieNomStatut-container {
  margin-left: 0.8rem;
}
.salarieNomStatut-container .salarieNom {
  font-weight: 700;
}
.salarieNomStatut-container .salarieStatut {
  font-size: 1.2rem;
}

.salarieBasicInfo {
  display: flex;
  align-items: center;
}

.intervenantsList .intervenantsItem {
  display: flex;
  align-items: center;
}
.intervenantsList .intervenantsItem:not(:last-child) {
  margin-bottom: 0.8rem;
}
.intervenantsList .intervenantBasicInfo {
  width: 35%;
}
.intervenantsList .intervenantAdresse {
  width: 35%;
  display: flex;
  align-items: center;
}
.intervenantsList .intervenantContacts {
  width: 30%;
  display: flex;
  align-items: center;
}
.intervenantsList .intervenantContacts.selectable {
  width: 20%;
}
.intervenantsList .intervenantNom {
  font-weight: 700;
}
.intervenantsList .intervenantCategorie {
  font-size: 1.2rem;
}
.intervenantsList .editBox-container {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-section h4 {
  margin-bottom: 1.2rem;
}

.relevee-heure-chef-top-container {
  margin-bottom: 2rem;
}

.relevee-heure-chef-topSection {
  display: flex;
  align-items: flex-end;
}
.relevee-heure-chef-topSection h2 {
  flex: 1;
}

.titleSemaineAndValidate-container {
  display: flex;
  margin-bottom: 0.8rem;
}
.titleSemaineAndValidate-container h1 {
  flex: 1;
}

.releve-heure-table-container {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
}

.releve-heure-table-container.semaine-valider {
  opacity: 0.6;
}

.heureAprem {
  background-color: #fff;
  border: 1px solid #dbdfea;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heureAprem p {
  font-size: 2.6rem;
  font-weight: 700;
  width: 10%;
  text-align: center;
  padding: 0 !important;
}

.heureMatin {
  background-color: #fff;
  border: 1px solid #dbdfea;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heureMatin p {
  font-size: 2.6rem;
  font-weight: 700;
  width: 10%;
  text-align: center;
  padding: 0 !important;
}

.totalHeure {
  align-items: center;
  background-color: #fff;
  border: 1px solid #dbdfea;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
}

.commentaireContainer {
  display: flex;
  flex-direction: column;
}

.heureInputAndLabelContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(45% - 10px);
  flex: 0 0 auto !important;
}
.heureInputAndLabelContainer .heureInputAndLabelContainer {
  width: 100% !important;
}

.select-search__value {
  height: 100%;
}

.commentaire-input {
  flex: 1;
}

.salarieHeure-td {
  border-bottom: 0;
  padding: 0 1.6rem !important;
  padding-top: 1.6rem !important;
}

.salarieAbsence-td {
  border-bottom: 0;
  padding: 0 1.6rem !important;
}

.salarieHeureAndForm-container {
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 1.6rem 2rem;
  background-color: #f5f5f5;
  box-shadow: 0rem 0.1rem 0.3rem 0rem rgba(54, 74, 99, 0.05);
}

.horizontal-form {
  padding: 0;
  margin-top: 1.6rem;
  margin-bottom: 0.8rem;
}

.react-time-input-picker-wrapper {
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 37px;
  justify-content: center;
  width: 100%;
  flex: 0 0 auto !important;
}

.react-time-input-picker {
  align-items: center;
  border-radius: 2px;
  color: #212121;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  flex: 0 0 auto !important;
}

.inputWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  width: 50%;
}

.input-time-mobile {
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
  padding: 0 0.8rem;
}

.resumeSalarieHeure {
  margin-top: 0.8rem;
}
.resumeSalarieHeure h3 {
  color: #1E1E1E;
  font-size: 1.4rem;
  margin-top: 0.8rem;
}
.resumeSalarieHeure:first-child {
  margin-top: 0;
}
.resumeSalarieHeure li {
  font-size: 1.6rem;
  font-weight: 400;
  color: #1E1E1E;
}

.chantierSelection {
  display: flex;
  flex-direction: column;
}
.chantierSelection .chantierInputEdit-container {
  display: flex;
}
.chantierSelection .chantierInputEdit-container .chantierInput-selected {
  border: 1px solid #dbdfea;
  flex: 1;
}
.chantierSelection .chantierInputEdit-container button {
  margin-right: 0;
}

.modifyHeure-container .heureModify-total .heureTotal-modify {
  padding: 0.4rem 1.6rem;
  border-radius: 0.5rem;
  border: 1px solid #dbdfea;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modifyHeure-container .heureModify-total label {
  margin-right: 0.8rem;
}
.modifyHeure-container .heureModify-inputLabel label {
  margin-right: 0.8rem;
}
.modifyHeure-container .heureModify-inputLabel .heureMatin-modify {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 1.6rem;
  border-radius: 0.5rem;
  border: 1px solid #dbdfea;
}
.modifyHeure-container .heureModify-inputLabel .heureMatin-modify p {
  padding: 0 2rem;
  font-size: 2.6rem;
  font-weight: 700;
}
.modifyHeure-container .heureModify-inputLabel .heureAprem-modify {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 1.6rem;
  border-radius: 0.5rem;
  border: 1px solid #dbdfea;
}
.modifyHeure-container .heureModify-inputLabel .heureAprem-modify p {
  padding: 0 2rem;
  font-size: 2.6rem;
  font-weight: 700;
}

.ajouterHeureSalarie-form {
  margin-top: 0.8rem;
  margin-bottom: 2rem;
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.8rem;
}

.admin-releve-table {
  margin-top: 1.6rem;
  height: auto !important;
}

.selectSalarie {
  flex: 3 1 0;
  white-space: nowrap;
}

.horaireContainer {
  flex: 2 1 0;
  white-space: nowrap;
}

.totalContainer {
  flex: 1 1 0;
  white-space: nowrap;
}

.commentaireContainer {
  white-space: nowrap;
  flex: 5 1 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
.commentaireContainer .commentaireOnly {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 0.8rem;
  min-width: 13rem;
}
.commentaireContainer .commentaireOnly input {
  width: 100%;
}

.ajouterHoraireButton-container {
  align-self: flex-end;
  display: flex;
  flex-wrap: nowrap;
}

.salarieReleveHeure-list li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 0.8rem;
}
.salarieReleveHeure-list li:last-child {
  margin-bottom: 0;
}
.salarieReleveHeure-list li .selectSalarie-readOnly-input {
  flex: 3 1 0;
}
.salarieReleveHeure-list li .selectHeure-readOnly-input {
  flex: 2 1 0;
  min-width: 19.8rem;
}
.salarieReleveHeure-list li .totalHeure-readOnly-input {
  flex: 1 1 0;
  min-width: 8.719rem;
}
.salarieReleveHeure-list li .salarieList-commentaireButton-container {
  flex: 5 1 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
.salarieReleveHeure-list li .commentaireInput-readOnly {
  flex: 1;
  margin-right: 0.8rem;
  min-width: 13rem;
}
.salarieReleveHeure-list h3 {
  margin-bottom: 0.4rem;
  margin-top: 0.8rem;
  flex-basis: 100%;
}
.salarieReleveHeure-list input {
  margin-right: 0;
  text-align: center;
}

.readOnly-input {
  white-space: nowrap;
  height: 4rem;
  background-color: #fff;
  border-radius: 5px;
  font-size: 1.5rem;
  border: 1px solid #dbdfea;
  color: #3c4d62;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
.readOnly-input::-moz-placeholder {
  opacity: 0.4;
}
.readOnly-input::placeholder {
  opacity: 0.4;
}

.containerFilterSection {
  display: flex;
  margin-top: 0.8rem;
}

.releveHeureSortBy {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  width: 30rem;
  margin-right: 0.8rem;
}
.releveHeureSortBy p {
  border: 1px solid #1E1E1E !important;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 0 0.8rem;
  flex: 1;
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.releveHeureSortBy p.select {
  background-color: #1E1E1E;
  color: #fff;
}
.releveHeureSortBy p:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.react-date-picker__wrapper {
  border-radius: 0.5rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  margin-right: 0.8rem;
}

.multipleSelectSalarie-container {
  flex: 1;
  display: flex;
  align-items: center;
}
.multipleSelectSalarie-container .mulitpleSelectSalarie {
  margin-right: 0.8rem;
  width: 20rem;
}
.multipleSelectSalarie-container .mulitpleSelectSalarie .dropdown-container {
  border: 1px solid #1E1E1E !important;
  border-radius: 0.5rem;
}
.multipleSelectSalarie-container .mulitpleSelectSalarie .gray {
  font-size: 1.5rem;
}
.multipleSelectSalarie-container .chipList {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 0.8rem;
  height: 100%;
}
.multipleSelectSalarie-container .chipList .chip {
  background-color: #1E1E1E;
  border-radius: 5rem;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1.6rem;
}
.multipleSelectSalarie-container .chipList .chip p {
  font-weight: 400;
  color: #fff;
  margin-right: 0.8rem;
  font-size: 1.3rem;
}
.multipleSelectSalarie-container .chipList .chip .closeIcon {
  color: #fff;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.pd-s1 {
  padding: 0.4rem !important;
}

.pdT-s1 {
  padding-top: 0.4rem !important;
}

.pdR-s1 {
  padding-right: 0.4rem !important;
}

.pdL-s1 {
  padding-left: 0.4rem !important;
}

.pdB-s1 {
  padding-bottom: 0.4rem !important;
}

.pdV-s1 {
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
}

.pdH-s1 {
  padding-right: 0.4rem !important;
  padding-left: 0.4rem !important;
}

.mg-s1 {
  margin: 0.4rem !important;
}

.mgT-s1 {
  margin-top: 0.4rem !important;
}

.mgR-s1 {
  margin-right: 0.4rem !important;
}

.mgL-s1 {
  margin-left: 0.4rem !important;
}

.mgB-s1 {
  margin-bottom: 0.4rem !important;
}

.mgV-s1 {
  margin-top: 0.4rem !important;
  margin-bottom: 0.4rem !important;
}

.mgH-s1 {
  margin-right: 0.4rem !important;
  margin-left: 0.4rem !important;
}

.pd-s2 {
  padding: 0.8rem !important;
}

.pdT-s2 {
  padding-top: 0.8rem !important;
}

.pdR-s2 {
  padding-right: 0.8rem !important;
}

.pdL-s2 {
  padding-left: 0.8rem !important;
}

.pdB-s2 {
  padding-bottom: 0.8rem !important;
}

.pdV-s2 {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.pdH-s2 {
  padding-right: 0.8rem !important;
  padding-left: 0.8rem !important;
}

.mg-s2 {
  margin: 0.8rem !important;
}

.mgT-s2 {
  margin-top: 0.8rem !important;
}

.mgR-s2 {
  margin-right: 0.8rem !important;
}

.mgL-s2 {
  margin-left: 0.8rem !important;
}

.mgB-s2 {
  margin-bottom: 0.8rem !important;
}

.mgV-s2 {
  margin-top: 0.8rem !important;
  margin-bottom: 0.8rem !important;
}

.mgH-s2 {
  margin-right: 0.8rem !important;
  margin-left: 0.8rem !important;
}

.pd-s3 {
  padding: 1.2rem !important;
}

.pdT-s3 {
  padding-top: 1.2rem !important;
}

.pdR-s3 {
  padding-right: 1.2rem !important;
}

.pdL-s3 {
  padding-left: 1.2rem !important;
}

.pdB-s3 {
  padding-bottom: 1.2rem !important;
}

.pdV-s3 {
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important;
}

.pdH-s3 {
  padding-right: 1.2rem !important;
  padding-left: 1.2rem !important;
}

.mg-s3 {
  margin: 1.2rem !important;
}

.mgT-s3 {
  margin-top: 1.2rem !important;
}

.mgR-s3 {
  margin-right: 1.2rem !important;
}

.mgL-s3 {
  margin-left: 1.2rem !important;
}

.mgB-s3 {
  margin-bottom: 1.2rem !important;
}

.mgV-s3 {
  margin-top: 1.2rem !important;
  margin-bottom: 1.2rem !important;
}

.mgH-s3 {
  margin-right: 1.2rem !important;
  margin-left: 1.2rem !important;
}

.pd-m1 {
  padding: 1.6rem !important;
}

.pdT-m1 {
  padding-top: 1.6rem !important;
}

.pdR-m1 {
  padding-right: 1.6rem !important;
}

.pdL-m1 {
  padding-left: 1.6rem !important;
}

.pdB-m1 {
  padding-bottom: 1.6rem !important;
}

.pdV-m1 {
  padding-top: 1.6rem !important;
  padding-bottom: 1.6rem !important;
}

.pdH-m1 {
  padding-right: 1.6rem !important;
  padding-left: 1.6rem !important;
}

.mg-m1 {
  margin: 1.6rem !important;
}

.mgT-m1 {
  margin-top: 1.6rem !important;
}

.mgR-m1 {
  margin-right: 1.6rem !important;
}

.mgL-m1 {
  margin-left: 1.6rem !important;
}

.mgB-m1 {
  margin-bottom: 1.6rem !important;
}

.mgV-m1 {
  margin-top: 1.6rem !important;
  margin-bottom: 1.6rem !important;
}

.mgH-m1 {
  margin-right: 1.6rem !important;
  margin-left: 1.6rem !important;
}

.pd-m2 {
  padding: 2rem !important;
}

.pdT-m2 {
  padding-top: 2rem !important;
}

.pdR-m2 {
  padding-right: 2rem !important;
}

.pdL-m2 {
  padding-left: 2rem !important;
}

.pdB-m2 {
  padding-bottom: 2rem !important;
}

.pdV-m2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.pdH-m2 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.mg-m2 {
  margin: 2rem !important;
}

.mgT-m2 {
  margin-top: 2rem !important;
}

.mgR-m2 {
  margin-right: 2rem !important;
}

.mgL-m2 {
  margin-left: 2rem !important;
}

.mgB-m2 {
  margin-bottom: 2rem !important;
}

.mgV-m2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.mgH-m2 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.pd-m3 {
  padding: 2.4rem !important;
}

.pdT-m3 {
  padding-top: 2.4rem !important;
}

.pdR-m3 {
  padding-right: 2.4rem !important;
}

.pdL-m3 {
  padding-left: 2.4rem !important;
}

.pdB-m3 {
  padding-bottom: 2.4rem !important;
}

.pdV-m3 {
  padding-top: 2.4rem !important;
  padding-bottom: 2.4rem !important;
}

.pdH-m3 {
  padding-right: 2.4rem !important;
  padding-left: 2.4rem !important;
}

.mg-m3 {
  margin: 2.4rem !important;
}

.mgT-m3 {
  margin-top: 2.4rem !important;
}

.mgR-m3 {
  margin-right: 2.4rem !important;
}

.mgL-m3 {
  margin-left: 2.4rem !important;
}

.mgB-m3 {
  margin-bottom: 2.4rem !important;
}

.mgV-m3 {
  margin-top: 2.4rem !important;
  margin-bottom: 2.4rem !important;
}

.mgH-m3 {
  margin-right: 2.4rem !important;
  margin-left: 2.4rem !important;
}

.pd-b1 {
  padding: 4.8rem !important;
}

.pdT-b1 {
  padding-top: 4.8rem !important;
}

.pdR-b1 {
  padding-right: 4.8rem !important;
}

.pdL-b1 {
  padding-left: 4.8rem !important;
}

.pdB-b1 {
  padding-bottom: 4.8rem !important;
}

.pdV-b1 {
  padding-top: 4.8rem !important;
  padding-bottom: 4.8rem !important;
}

.pdH-b1 {
  padding-right: 4.8rem !important;
  padding-left: 4.8rem !important;
}

.mg-b1 {
  margin: 4.8rem !important;
}

.mgT-b1 {
  margin-top: 4.8rem !important;
}

.mgR-b1 {
  margin-right: 4.8rem !important;
}

.mgL-b1 {
  margin-left: 4.8rem !important;
}

.mgB-b1 {
  margin-bottom: 4.8rem !important;
}

.mgV-b1 {
  margin-top: 4.8rem !important;
  margin-bottom: 4.8rem !important;
}

.mgH-b1 {
  margin-right: 4.8rem !important;
  margin-left: 4.8rem !important;
}

.pd-b2 {
  padding: 8rem !important;
}

.pdT-b2 {
  padding-top: 8rem !important;
}

.pdR-b2 {
  padding-right: 8rem !important;
}

.pdL-b2 {
  padding-left: 8rem !important;
}

.pdB-b2 {
  padding-bottom: 8rem !important;
}

.pdV-b2 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.pdH-b2 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.mg-b2 {
  margin: 8rem !important;
}

.mgT-b2 {
  margin-top: 8rem !important;
}

.mgR-b2 {
  margin-right: 8rem !important;
}

.mgL-b2 {
  margin-left: 8rem !important;
}

.mgB-b2 {
  margin-bottom: 8rem !important;
}

.mgV-b2 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.mgH-b2 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.pd-b {
  padding: 3.2rem !important;
}

.pdT-b {
  padding-top: 3.2rem !important;
}

.pdR-b {
  padding-right: 3.2rem !important;
}

.pdL-b {
  padding-left: 3.2rem !important;
}

.pdB-b {
  padding-bottom: 3.2rem !important;
}

.pdV-b {
  padding-top: 3.2rem !important;
  padding-bottom: 3.2rem !important;
}

.pdH-b {
  padding-right: 3.2rem !important;
  padding-left: 3.2rem !important;
}

.mg-b {
  margin: 3.2rem !important;
}

.mgT-b {
  margin-top: 3.2rem !important;
}

.mgR-b {
  margin-right: 3.2rem !important;
}

.mgL-b {
  margin-left: 3.2rem !important;
}

.mgB-b {
  margin-bottom: 3.2rem !important;
}

.mgV-b {
  margin-top: 3.2rem !important;
  margin-bottom: 3.2rem !important;
}

.mgH-b {
  margin-right: 3.2rem !important;
  margin-left: 3.2rem !important;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60rem;
  height: auto;
  padding: 4rem 0;
  background-color: #fff;
  border-radius: 4px;
  border: 0 solid rgba(0, 0, 0, 0.125);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.login-container form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  height: 100%;
}
.login-container form img {
  width: 50%;
  align-self: center;
  margin-bottom: 8rem;
}
.login-container form .login-label {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: #344357;
  padding-bottom: 0.4rem;
}
.login-container form .login-sentence {
  margin-bottom: 2rem;
  font-size: 1.65rem;
  font-weight: 400;
  line-height: 3rem;
  color: #8094ae;
}
.login-container form .login-sentence.error_sentence {
  color: #d13852;
}
.login-container form .loginWrapper {
  position: relative;
  margin-bottom: 0.8rem;
}
.login-container form .login-field {
  width: 100%;
  padding: 0.4rem 1.6rem;
  height: 4rem;
  border-radius: 5px;
  font-size: 1.5rem;
  border: 1px solid #dbdfea;
  color: #3c4d62;
  font-weight: 400;
  box-sizing: border-box;
}
.login-container form .login-field::-moz-placeholder {
  opacity: 0.4;
}
.login-container form .login-field::placeholder {
  opacity: 0.4;
}
.login-container form .loginIcon {
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  background-color: #fff;
  border-radius: 100rem;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  right: 1.6rem;
  transform: translateY(-50%);
}
.login-container form .loginIcon svg {
  color: #1E1E1E;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.login-container form .checkbox-container {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 400;
  color: #3c4d62;
}
.login-container form .checkbox-container .login-checkbox {
  transform: scale(1.1);
  margin-right: 0.4rem;
  cursor: pointer;
}
.login-container form .checkbox-container:hover {
  color: #d13852;
  cursor: pointer;
}
.login-container form .checkbox-container .checkbox-label-login {
  cursor: pointer;
}
.login-container form .login-error-message {
  margin-bottom: 1.6rem;
  color: #d13852;
  font-size: 1.3rem;
}
.login-container form .login-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login-container form .login-options p {
  font-size: 1.5rem;
  color: #3c4d62;
  cursor: pointer;
  font-weight: 400;
}
.login-container form .login-options p:hover {
  color: #d13852;
}
.login-container hr {
  height: 1px;
  padding: 0;
  color: #344357;
  background-color: #344357;
  border: 0;
  margin-bottom: 1.6rem;
}

.loginWrapper {
  position: relative;
  margin-bottom: 0.8rem;
}

.loginIcon {
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  background-color: #fff;
  border-radius: 100rem;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  right: 1.6rem;
  transform: translateY(-50%);
}
.loginIcon svg {
  color: #1E1E1E;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.button {
  cursor: pointer;
}
.button.login-button {
  margin-top: 8rem;
  padding: 0.8rem 2rem;
  height: 5rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  color: #fff;
  background-color: #1E1E1E;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.button.login-button:hover {
  background-color: #0a0a0a;
}
.button.login-button:active {
  outline: 0.3rem solid #9e9e9e;
}

.default-button {
  cursor: pointer;
  padding: 0.8rem 1.6rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  color: #fff;
  font-weight: 700 !important;
  background-color: #1E1E1E;
  font-size: 1.5rem;
  line-height: 2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.default-button:hover {
  background-color: #0a0a0a;
}
.default-button:active {
  outline: 0.3rem solid #777777;
}
.default-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.logout_button {
  font-size: 1.4rem;
  border: 1px solid transparent;
  color: #1E1E1E;
  cursor: pointer;
  height: 100%;
  padding: 1.6rem;
  font-weight: 500;
  background-color: #f6f6f6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.logout_button:hover {
  color: white;
  background-color: #d13852;
}

.fullEdit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0.8rem 1.6rem;
  cursor: pointer;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.fullEdit-button p {
  margin-left: 0.8rem;
  font-weight: 700;
  font-size: 1.5rem;
  color: white;
}
.fullEdit-button svg {
  width: 2rem;
  height: 2rem;
  fill: white;
  color: white;
}

.round-button {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 100rem;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.round-button svg {
  width: 2.4rem;
  height: 2.4rem;
  fill: white;
  color: white;
  margin: 0.2rem 0 0.2rem 0.2rem;
}

.round-button-small {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 100rem;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.round-button-small svg {
  width: 2.4rem;
  height: 2.4rem;
  fill: white;
  color: white;
}

.green-button {
  background-color: #2fc451;
  border-color: #2fc451;
}
.green-button:hover {
  background-color: #28a745;
}
.green-button:active {
  outline: 0.3rem solid #d8f6df;
}

.red-button {
  background-color: #d8556b;
  border-color: #d8556b;
}
.red-button:hover {
  background-color: #d13852;
  border-right: 1px solid #AF3247;
}
.red-button:last-child {
  margin-right: 0;
}
.red-button.active {
  outline: 0.3rem solid #f3c9d0;
}

.yellow-button {
  background-color: #deb348;
  border-color: #deb348;
}
.yellow-button:hover {
  background-color: #d8a72a;
}
.yellow-button:active {
  outline: 0.3rem solid white;
}

.black-button {
  background-color: #1E1E1E;
  border-color: #1E1E1E;
}
.black-button:hover {
  background-color: #0a0a0a;
}
.black-button:active {
  outline: 0.3rem solid #9e9e9e;
}

.modal-button {
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  color: #fff;
  font-weight: 700;
  background-color: #d8556b;
  font-size: 1.5rem;
  line-height: 2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.modal-button:hover {
  background-color: #d13852;
}
.modal-button:active {
  outline: 0.3rem solid #9e9e9e;
}
.modal-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.ajouter-salarie-button {
  color: white !important;
  margin-right: 0 !important;
  height: 5rem !important;
  background-color: #2fc451;
  border-color: #2fc451;
}
.ajouter-salarie-button:hover {
  background-color: #28a745;
}
.ajouter-salarie-button:active {
  outline: 0.3rem solid #d8f6df;
}

.table-buton-container {
  display: flex;
}

.table-modify-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  margin-right: 0.8rem;
  padding: 0.4rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  fill: white;
  color: white;
  background-color: #2fc451;
  border-color: #2fc451;
}
.table-modify-button:hover {
  background-color: #28a745;
}
.table-modify-button:active {
  outline: 0.3rem solid #d8f6df;
}
.table-modify-button svg {
  width: 100%;
  height: 100%;
}

.table-archive-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  margin-right: 0.8rem;
  padding: 0.4rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  color: white;
  fill: white;
  background-color: #deb348;
  border-color: #deb348;
}
.table-archive-button:hover {
  background-color: #d8a72a;
}
.table-archive-button:active {
  outline: 0.3rem solid white;
}
.table-archive-button svg {
  width: 100%;
  height: 100%;
}

.table-delete-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  fill: white;
  padding: 0.8rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  cursor: pointer;
  background-color: #d8556b;
  border-color: #d8556b;
}
.table-delete-button:hover {
  background-color: #d13852;
}
.table-delete-button:active {
  outline: 0.3rem solid #f3c9d0;
}
.table-delete-button svg {
  width: 100%;
  height: 100%;
}

.table-unlocked-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  fill: white;
  padding: 0.8rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  cursor: pointer;
  background-color: #1E1E1E;
  border-color: #1E1E1E;
}
.table-unlocked-button:hover {
  background-color: #0a0a0a;
}
.table-unlocked-button:active {
  outline: 0.3rem solid #777777;
}
.table-unlocked-button svg {
  width: 100%;
  height: 100%;
}

.validate-button-fw {
  cursor: pointer;
  color: white;
  height: 5rem;
  background-color: #2fc451;
  border-color: #2fc451;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0.8rem 1.6rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-weight: 700 !important;
}
.validate-button-fw:hover {
  background-color: #28a745;
}
.validate-button-fw:active {
  outline: 0.3rem solid #d8f6df;
}

.generate-mdp-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 3.8rem;
  height: 3.8rem;
  padding: 0.8rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  fill: white;
  background-color: #1E1E1E;
  border-radius: 0.5rem;
  border: 1px solid transparent;
}
.generate-mdp-button:hover {
  background-color: #0a0a0a;
}
.generate-mdp-button:active {
  outline: 0.3rem solid #9e9e9e;
}
.generate-mdp-button svg {
  width: 100%;
  height: 100%;
  color: white;
}

.select-semaine-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 4rem;
  height: 100%;
  padding: 0.8rem;
  border: 1px solid transparent;
  fill: white;
  background-color: #1E1E1E;
  border: 1px solid transparent;
}
.select-semaine-button-right {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.select-semaine-button-left {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.select-semaine-button:hover {
  background-color: #0a0a0a;
}
.select-semaine-button:active {
  outline: 0.3rem solid #9e9e9e;
}
.select-semaine-button svg {
  width: 100%;
  height: 100%;
  color: white;
}

.disabled-button {
  opacity: 0.6 !important;
}

.ajouterChantier-button {
  display: block;
  margin: auto;
  background: #1E1E1E !important;
  opacity: 1;
}
.ajouterChantier-button:hover {
  background-color: #0a0a0a;
}
.ajouterChantier-button:active {
  outline: 0.3rem solid #9e9e9e;
}

.threeDot-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  border-radius: 50%;
  padding: 0.8rem;
  transition: background 0.3s ease-in-out;
}
.threeDot-button:hover {
  background-color: #1E1E1E !important;
  color: #ffffff;
}
.threeDot-button:hover svg {
  fill: #fff;
}
.threeDot-button:hover svg path {
  fill: #fff;
}
.threeDot-button.hovered {
  background-color: #1E1E1E !important;
  color: #ffffff;
}
.threeDot-button.hovered svg {
  fill: #fff;
}
.threeDot-button.hovered svg path {
  fill: #fff;
}
.threeDot-button svg {
  transition: fill 0.3s ease-in-out;
  width: 2rem;
  height: 2rem;
  fill: #1E1E1E;
}
.threeDot-button svg path {
  fill: #1E1E1E;
}

.default-radius {
  border-radius: 0.5rem;
}

.addBanque-button, .validerSemaine-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  height: 5rem;
  background-color: #2fc451;
  border-color: #2fc451;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0.8rem 1.6rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-weight: 700 !important;
  margin-right: 0.8rem;
}
.addBanque-button:hover, .validerSemaine-button:hover {
  background-color: #28a745;
}
.addBanque-button:active, .validerSemaine-button:active {
  outline: 0.3rem solid #d8f6df;
}

.removeBanque-button, .devaliderSemaine-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  height: 5rem;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0.8rem 1.6rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-weight: 700 !important;
  background-color: #d8556b;
  border-color: #d8556b;
}
.removeBanque-button:hover, .devaliderSemaine-button:hover {
  background-color: #d13852;
}
.removeBanque-button:active, .devaliderSemaine-button:active {
  outline: 0.3rem solid #f3c9d0;
}

.banqueHeure-button {
  margin-top: 1.6rem;
  width: 100%;
  text-align: center;
  border: 1px solid transparent;
  background-color: #1E1E1E;
}
.banqueHeure-button:hover {
  background-color: #0a0a0a;
}
.banqueHeure-button:active {
  outline: 0.3rem solid #9e9e9e;
}

.downloadButtonApp {
  width: 14.2rem;
  font-size: 1.4rem;
  border: 1px solid transparent;
  color: #1E1E1E;
  cursor: pointer;
  height: 100%;
  padding: 1.6rem;
  margin-right: 0.8rem;
  border-right: 1px solid #D9DCDD;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  background-color: #f6f6f6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.downloadButtonApp p {
  margin-right: 0.8rem;
}
.downloadButtonApp:hover {
  color: white;
  background-color: #d13852;
  border-right: 1px solid #AF3247;
}
.downloadButtonApp:last-child {
  margin-right: 0;
}
.downloadButtonApp.active {
  color: white;
  background-color: #d13852;
  border-right: 1px solid #AF3247;
}

.ajouterHeureButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  margin-right: 0.8rem;
  cursor: pointer;
  background-color: #2fc451;
  border-color: #2fc451;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.ajouterHeureButton:hover {
  background-color: #28a745;
}
.ajouterHeureButton:active {
  outline: 0.3rem solid #d8f6df;
}
.ajouterHeureButton svg {
  height: 2.5rem;
  width: 2.5rem;
  color: #fff;
}

.table-black-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  margin-right: 0.8rem;
  padding: 0.4rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  color: white;
  background-color: #1E1E1E;
  border-color: #1E1E1E;
}
.table-black-button:hover {
  background-color: #0a0a0a;
}
.table-black-button:active {
  outline: 0.3rem solid #9e9e9e;
}
.table-black-button svg {
  width: 100%;
  height: 100%;
}

.ajouterAbsenceButton {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  color: white;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #deb348;
  border-color: #deb348;
}
.ajouterAbsenceButton:hover {
  background-color: #d8a72a;
}
.ajouterAbsenceButton:active {
  outline: 0.3rem solid white;
}
.ajouterAbsenceButton svg {
  width: 2.3rem;
  height: 2.3rem;
}

.backButton {
  color: #1E1E1E;
  width: 3rem;
  height: 3rem;
  margin-right: 2rem;
  transition: transform 0.3s ease-in-out;
}
.backButton:hover {
  opacity: 0.8;
}

.chip-autoControle {
  background-color: #1E1E1E;
  border-radius: 5rem;
  height: 100%;
  padding: 1rem 1.4rem;
}
.chip-autoControle p {
  font-weight: 400;
  color: #fff;
  font-size: 1.3rem;
}

.btn {
  font-size: 1.4rem;
  outline: none;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  touch-action: manipulation;
  line-height: 1.5714285714;
  color: rgba(0, 0, 0, 0.88);
  min-height: 3.8rem;
  padding: 0.4rem 1.5rem;
}
.btn.fw {
  width: 100%;
}
.btn.disabled-button {
  opacity: 0.65;
}
.btn.medium-btn {
  min-height: 4.4rem;
}
.btn.small-btn {
  min-height: 3rem;
  fontSize: 1.2rem;
}
.btn-primary {
  background-color: #1E1E1E;
  border-radius: 0.6rem;
  color: #fff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}
.btn-primary.square {
  min-width: 3.2rem;
  padding-inline-start: 0;
  padding-inline-end: 0;
  border-radius: 0.8rem;
}
.btn-primary.iconOnly {
  width: 3.2rem;
  padding-inline-start: 0;
  padding-inline-end: 0;
  border-radius: 0.8rem;
}
.btn-primary:hover {
  color: #fff;
  background-color: #2b2b2b;
}
.btn-primary:active {
  color: #fff;
  background-color: #000000;
  outline: 0.3rem solid #9e9e9e;
}
.btn-secondary {
  background-color: #fff;
  border-radius: 0.6rem;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  border-color: #d9d9d9;
}
.btn-secondary.square {
  min-width: 3.2rem;
  padding-inline-start: 0;
  padding-inline-end: 0;
  border-radius: 0.8rem;
}
.btn-secondary.iconOnly {
  width: 3.2rem;
  padding-inline-start: 0;
  padding-inline-end: 0;
  border-radius: 0.8rem;
}
.btn-secondary:hover {
  color: #2b2b2b;
  border-color: #2b2b2b;
}
.btn-secondary:active {
  color: #000000;
  border-color: #000000;
}

.btn-actualitePublic {
  background-color: rgba(70, 167, 40, 0.2823529412);
  color: #2fc451;
  font-weight: 500;
  width: 100%;
  border: 2px solid #2fc451;
  border-radius: 0.5rem;
}

.button-container-flex {
  display: flex;
}

.supprimerButton {
  background-color: #d8556b;
  display: flex;
  align-items: center;
  color: #fff;
}
.supprimerButton svg {
  fill: #fff;
  width: 2.2rem;
  height: 2.2rem;
}
.supprimerButton:hover {
  color: #fff;
  background-color: #d13852;
}
.supprimerButton:active {
  color: #fff;
  background-color: #d13852;
  outline: 0.3rem solid #f3c9d0;
}

.oldButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  height: 5rem;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0.8rem 1.6rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-weight: 700 !important;
}

.roundButton-ajouterDemande {
  margin-top: 5px;
  border: none;
  background-color: rgba(0, 0, 0, 0.88);
  color: #fff;
  border-radius: 50%;
  width: 2.8rem;
  height: 2.8rem;
  cursor: pointer;
}

.selectionDemandeButton {
  display: flex;
  align-items: center;
}
.selectionDemandeButton svg {
  width: 2rem;
  height: 2rem;
}
.selectionDemandeButton svg:first-child {
  margin-right: 1.6rem;
}
.selectionDemandeButton p {
  flex: 1;
  text-align: left;
  font-weight: 700;
}

.sidebar {
  border-right: 1px solid #e5e9f2;
  box-shadow: 0rem 0.1rem 0.3rem 0rem rgba(54, 74, 99, 0.05);
  transition: transform 450ms ease, width 450ms ease;
  height: 100%;
  min-height: 100vh;
  width: 29rem;
  background-color: #fff;
  z-index: 20;
  position: fixed;
  overflow-y: auto;
  padding-bottom: 2rem;
}
.sidebar.small_sidebar {
  width: 7rem !important;
}
.sidebar.small_sidebar .sidebar__main-nested-link {
  padding-left: 2rem;
}
.sidebar.small_sidebar .sidebar__main-linkItem {
  justify-content: center;
}
.sidebar.small_sidebar .sidebar__main-linkItem .link-icon {
  margin-right: 0;
}
.sidebar.small_sidebar .sidebar__main-linkItem-text {
  display: none;
}
.sidebar.small_sidebar .sidebar__main-linkItem .chevron-right {
  display: none;
}
.sidebar.small_sidebar .subMenu-linklist {
  display: none;
}
.sidebar.small_sidebar .sidebar__main-linkContainer {
  padding: 0.8rem 0.8rem;
}
.sidebar.small_sidebar .sidebar__main-linkContainer:first-child {
  padding-top: 0.8rem;
}
.sidebar.small_sidebar .sidebar__main-linkContainer:last-child {
  padding-bottom: 0;
}
.sidebar.small_sidebar .sidebar__separator {
  margin: 0 0.8rem;
}
.sidebar.openHover {
  width: 29rem !important;
}
.sidebar.openHover .sidebar__main-nested-link {
  padding-left: calc(2.4rem + 0.8rem + 0.8rem) !important;
}
.sidebar.openHover .sidebar__main-linkItem {
  justify-content: flex-start !important;
}
.sidebar.openHover .sidebar__main-linkItem .link-icon {
  margin-right: 0.8rem !important;
}
.sidebar.openHover .sidebar__main-linkItem-text {
  display: inline-block !important;
  flex: 1 !important;
}
.sidebar.openHover .sidebar__main-linkItem .chevron-right {
  display: inline-block !important;
}
.sidebar.openHover .subMenu-linklist {
  display: block !important;
}
.sidebar.openHover .sidebar__main-linkContainer {
  padding: 0.8rem 1.6rem;
}
.sidebar.openHover .sidebar__main-linkContainer:first-child {
  padding-top: 0.8rem;
}
.sidebar.openHover .sidebar__main-linkContainer:last-child {
  padding-bottom: 0;
}
.sidebar.openHover .sidebar__separator {
  margin: 0 1.6rem;
}
.sidebar__top {
  width: 60%;
  display: flex;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 3.2rem;
  height: 65px;
  align-items: center;
}
.sidebar__top-logo {
  max-width: 100%;
}
.sidebar__top .menuIconSidebar {
  width: 3rem;
  height: 3rem;
  display: none;
  margin: auto 0;
  stroke: #1E1E1E;
  cursor: pointer;
}
.sidebar__separator {
  color: #d9d9d9;
  background-color: #d9d9d9;
  margin: 0 1.6rem;
}
.sidebar__main-linkContainer {
  width: 100%;
  padding: 0.8rem 1.6rem;
}
.sidebar__main-linkContainer:first-child {
  padding-top: 0.8rem;
}
.sidebar__main-linkContainer:last-child {
  padding-bottom: 0;
}
.sidebar__main-linkItem {
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;
  border-radius: 0.6rem;
  font-weight: 500;
  color: #1E1E1E;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  padding: 0.8rem;
}
.sidebar__main-linkItem-linkActive {
  background-color: #f6f6f6;
  color: #d13852;
}
.sidebar__main-linkItem .chevron-right {
  overflow: hidden;
  width: 2.4rem;
  height: 2.4rem;
  fill: #1E1E1E;
  transform: translateY(0.15rem);
  transition: transform 0.2s ease-in-out;
}
.sidebar__main-linkItem .chevron-right-opened {
  transform: rotate(90deg);
}
.sidebar__main-linkItem-text {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  transition: all 0.2s ease;
}
.sidebar__main-linkItem .link-icon {
  width: 2.4rem;
  height: 2.4rem;
  fill: #1E1E1E;
  margin-right: 0.8rem;
  transition: fill 0.3s;
}
.sidebar__main-linkItem .link-icon-hover {
  fill: #d13852;
}
.sidebar__main-linkItem:hover {
  background-color: #f6f6f6;
  color: #d13852;
}
.sidebar__main .subMenu-linklist {
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}
.sidebar__main-nested-link {
  cursor: pointer;
  font-weight: 400;
  font-size: 1.6rem;
  color: rgba(30, 30, 30, 0.8);
  padding-left: calc(2.4rem + 0.8rem + 0.8rem);
  transition: color 0.3s;
  padding-bottom: 0.8rem;
}
.sidebar__main-nested-link-linkActive {
  color: #d13852;
}
.sidebar__main-nested-link:hover {
  color: #d13852;
}
.sidebar__main-nested-link:first-child {
  margin-top: 0.4rem;
}
.sidebar__main-nested-link:last-child {
  padding-bottom: 0;
}

.dashboard-layout-main-container {
  display: flex;
  flex-direction: column;
}

.dashboard-layout-page-content {
  flex: 1;
}

.noSidebar {
  font-size: 40%;
}
.noSidebar .sidebar {
  display: none !important;
}
.noSidebar .dashboard-layout-main-container {
  margin-left: 0 !important;
}
.noSidebar .dashboard-layout-main-container.small_sidebar .dashboard-header {
  width: 100% !important;
}
.noSidebar .dashboard-header {
  width: 100% !important;
}
.noSidebar .section {
  margin-left: 0;
  margin-right: 0;
}

.dashboard-header {
  width: calc(100% - 29rem);
  background-color: #fff;
  height: 6.5rem;
  box-shadow: 0rem 0.1rem 0.3rem 0rem rgba(54, 74, 99, 0.05);
  border-bottom: 1px solid #e5e9f2;
  z-index: 10;
  position: fixed;
  display: flex;
  align-items: center;
  padding-right: 4.8rem;
  justify-content: flex-end;
}
.dashboard-header .sidebarToggle-button {
  padding: 1rem;
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #1E1E1E;
}
.dashboard-header .sidebarToggle-button svg {
  height: 100%;
  width: 100%;
}
.dashboard-header .fullBar {
  flex: 1;
  height: 100%;
}

.dashboard-header-userAccount-bigContainer {
  min-width: 14rem;
  position: relative;
  height: 100%;
}
.dashboard-header-userAccount-bigContainer .userAccount__menu {
  position: absolute;
  width: 100%;
  top: 6.45rem;
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: opacity 0.15s ease-in-out;
}
.dashboard-header-userAccount-bigContainer .userAccount__menu hr {
  height: 0.5px;
}
.dashboard-header-userAccount-bigContainer .userAccount__menu .smallAccountItems {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  color: #1E1E1E;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
}
.dashboard-header-userAccount-bigContainer .userAccount__menu .smallAccountItems:hover {
  cursor: pointer;
  background-color: #d13852;
  color: #fff;
}
.dashboard-header-userAccount-bigContainer .userAccount__menu .smallAccountItems svg {
  width: 2rem;
  height: 2rem;
  margin-right: 1.6rem;
}
.dashboard-header-userAccount-bigContainer .userAccount__menu .smallAccountItems:last-child {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.dashboard-header-userAccount-bigContainer .userAccount__menu h3 {
  cursor: pointer;
}

.dashboard-header-userAccount-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.dashboard-header-userAccount-container .username-role-container {
  padding: 0.8rem;
  padding-left: 0;
}
.dashboard-header-userAccount-container .username-role-container .userRole {
  color: #d8556b;
  font-weight: 500;
  line-height: 1.1rem;
  font-size: 1.1rem;
  padding-bottom: 0.4rem;
}
.dashboard-header-userAccount-container .username-role-container .userIdentity {
  display: flex;
  align-items: flex-end;
}
.dashboard-header-userAccount-container .username-role-container .userIdentity h3 {
  line-height: 1.6rem;
  line-height: 1;
  font-size: 1.3rem;
  font-weight: 700;
  color: #1E1E1E;
}
.dashboard-header-userAccount-container .down-arrow {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1.6rem;
  transition: transform 0.2s ease-in-out;
}
.dashboard-header-userAccount-container .down-arrow_open {
  transform: rotate(90deg);
}
.dashboard-header-userAccount-container .userIcon {
  width: 2.9rem;
  height: 2.9rem;
  margin-right: 0.4rem;
  color: #d8556b;
}

.dashboard-header-chef {
  width: 100%;
  background-color: #fff;
  height: 6.5rem;
  box-shadow: 0rem 0.1rem 0.3rem 0rem rgba(54, 74, 99, 0.05);
  border-bottom: 1px solid #e5e9f2;
  padding-right: 4.8rem;
  padding-left: 4.8rem;
  display: flex;
  align-items: center;
}
.dashboard-header-chef .left-header-chef {
  flex: 1;
  justify-content: start;
}
.dashboard-header-chef .chef-header-logo {
  width: 12rem;
}
.dashboard-header-chef .right-header-chef {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  flex: 1;
}
.dashboard-header-chef .middle-header-chef {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
}
.dashboard-header-chef .chef-button-nav {
  width: 14.2rem;
  font-size: 1.4rem;
  border: 1px solid transparent;
  color: #1E1E1E;
  cursor: pointer;
  height: 100%;
  padding: 1.6rem;
  margin-right: 0;
  border-right: 1px solid #D9DCDD;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  background-color: #f6f6f6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.dashboard-header-chef .chef-button-nav:hover {
  color: white;
  background-color: #d13852;
  border-right: 1px solid #AF3247;
}
.dashboard-header-chef .chef-button-nav:last-child {
  margin-right: 0;
}
.dashboard-header-chef .chef-button-nav-active {
  color: white;
  background-color: #d13852;
}

.dashboard-header-ouvrier {
  width: 100%;
  background-color: #fff;
  height: 6.5rem;
  box-shadow: 0rem 0.1rem 0.3rem 0rem rgba(54, 74, 99, 0.05);
  border-bottom: 1px solid #e5e9f2;
  padding-right: 4.8rem;
  padding-left: 4.8rem;
  display: flex;
  align-items: center;
}
.dashboard-header-ouvrier .left-header-ouvrier {
  flex: 1;
  justify-content: start;
}
.dashboard-header-ouvrier .ouvrier-header-logo {
  width: 12rem;
}
.dashboard-header-ouvrier .right-header-ouvrier {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  flex: 1;
}
.dashboard-header-ouvrier .ouvrier-button-nav {
  width: 14.2rem;
  font-size: 1.4rem;
  border: 1px solid transparent;
  color: #1E1E1E;
  cursor: pointer;
  height: 100%;
  padding: 1.6rem;
  margin-right: 0;
  border-right: 1px solid #D9DCDD;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  background-color: #f6f6f6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.dashboard-header-ouvrier .ouvrier-button-nav:hover {
  color: white;
  background-color: #d13852;
  border-right: 1px solid #AF3247;
}
.dashboard-header-ouvrier .ouvrier-button-nav:last-child {
  margin-right: 0;
}
.dashboard-header-ouvrier .ouvrier-button-nav-active {
  color: white;
  background-color: #d13852;
}

.topTabBar {
  display: flex;
  height: 100%;
}
.topTabBar li {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin-right: 4.8rem;
  font-weight: 400;
  font-size: 1.4rem;
  transition: all 0.3s;
  color: rgba(30, 30, 30, 0.8);
  border-bottom-width: 2px;
  border-bottom-color: #fff;
  border-bottom-style: solid;
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  text-align: center;
  height: 100%;
}
.topTabBar li:hover {
  color: #d13852;
}
.topTabBar li.active {
  color: #d13852;
  border-bottom-color: #d13852;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  font-size: 1.5rem;
  color: #1E1E1E;
}

td {
  font-size: 1.3rem;
  color: #1E1E1E;
}

.filter-icon {
  color: #1E1E1E;
  stroke: #1E1E1E;
  width: 1.2rem;
  height: 1.2rem;
}
.filter-icon__haut {
  margin-left: 1.6rem;
}
.filter-icon__bas {
  transform: rotate(180deg) translateX(1.2rem) translateY(calc(-1.2rem + 0.3rem));
}
.filter-icon__active {
  color: #d13852;
  stroke: #d13852;
}

.tableau-top-section {
  display: flex;
}
.tableau-top-section h3 {
  flex: 1;
  color: rgba(30, 30, 30, 0.8);
  font-size: 1.3rem;
  font-weight: 400;
  align-self: flex-end;
}

.releve-heure-chef-table {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
}
.releve-heure-chef-table thead th {
  background-color: #fff;
  padding: 1.6rem 2rem;
  border-spacing: 4.8rem 0 !important;
}
.releve-heure-chef-table thead th:last-child {
  border-top-right-radius: 0.5rem;
}
.releve-heure-chef-table thead th:first-child {
  border-top-left-radius: 0.5rem;
}
.releve-heure-chef-table tbody td {
  background-color: #fff;
  padding: 1.6rem 2rem;
}
.releve-heure-chef-table tbody tr {
  cursor: pointer;
}
.releve-heure-chef-table tbody tr .jourTableRow {
  padding-left: 5px !important;
}
.releve-heure-chef-table tbody .jourTableRow {
  height: 5vh;
  background-color: #FFFFFF !important;
  position: relative !important;
}
.releve-heure-chef-table tbody .jourTableRow td {
  transition: background-color 0.3s, box-shadow 0.3s;
}
.releve-heure-chef-table tbody .jourTableRow td:first-child::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #FFFFFF !important;
  transition: all 0.2s ease-in-out;
}
.releve-heure-chef-table tbody .jourTableRow:hover {
  box-shadow: none !important;
  transform: initial !important;
  transition: background-color 0.3s;
}
.releve-heure-chef-table tbody .jourTableRow:hover td {
  background-color: #F6F6F6 !important;
  color: #1E1E1E;
}
.releve-heure-chef-table tbody .jourTableRow:hover td:first-child::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #D13852 !important;
}
.releve-heure-chef-table tbody .jourTableRow.RowActive {
  box-shadow: none !important;
  transform: initial !important;
  transition: background-color 0.3s;
}
.releve-heure-chef-table tbody .jourTableRow.RowActive td {
  background-color: #F6F6F6 !important;
  color: #1E1E1E;
}
.releve-heure-chef-table tbody .jourTableRow.RowActive td:first-child::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #D13852 !important;
}
.releve-heure-chef-table tbody .jourTableSubRow {
  cursor: default;
}

.pagination button {
  background-color: #ffffff;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  padding: 0.8rem 1rem;
}
.pagination button:disabled {
  opacity: 0.5;
}
.pagination input[type=number] {
  width: 60px !important;
}

.default-table thead th {
  background-color: #fff;
  padding: 1.2rem 2rem;
  border-spacing: 4.8rem 0 !important;
}
.default-table thead th:last-child {
  border-top-right-radius: 0.5rem;
}
.default-table thead th:first-child {
  border-top-left-radius: 0.5rem;
}
.default-table tbody td {
  background-color: #fff;
  padding: 1.2rem 2rem;
}
.default-table tbody tr td {
  transition: background-color 0.3s, box-shadow 0.3s;
}
.default-table tbody tr:hover {
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  transform: scale(1);
}
.default-table tbody tr:hover td {
  background-color: #fafafa;
}

.simple-table-container .simple-table thead th {
  background-color: #fff;
  padding: 1.2rem 0;
  border-spacing: 4.8rem 0 !important;
  font-weight: 300;
  font-size: 1.4rem;
  color: rgba(30, 30, 30, 0.5019607843);
  transition: color 0.2s ease;
}
.simple-table-container .simple-table thead th:hover {
  color: #d8556b;
}
.simple-table-container .simple-table tbody td {
  background-color: #fff;
  padding: 1.2rem 0;
  font-size: 1.4rem;
}
.simple-table-container .simple-table tbody tr td {
  transition: background-color 0.3s, box-shadow 0.3s;
}
.simple-table-container .simple-table tbody tr:hover {
  transition: background-color 0.3s, box-shadow 0.3s;
  transform: scale(1);
}
.simple-table-container .simple-table tbody tr:hover td {
  background-color: #fafafa;
}

.pagination {
  display: flex;
  align-items: center;
  padding: 0.8rem 2rem;
  justify-content: space-between;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.pagination .nombreLigneParPage-pagination {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  color: #1E1E1E;
}
.pagination .nombreLigneParPage-pagination select {
  border: 1px solid #1E1E1E;
  border-radius: 0.5rem;
  padding: 0.4rem;
  margin-left: 0.4rem;
}
.pagination .goToPage-pagination {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  color: #1E1E1E;
}
.pagination .goToPage-pagination input {
  border: 1px solid #1E1E1E;
  border-radius: 0.5rem;
  padding: 0.4rem 0.8rem;
  margin-left: 0.4rem;
}
.pagination .nbPageAndButton-paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination .nbPageAndButton-paginationContainer .nombrePage-pagination {
  font-size: 1.3rem;
  color: #1E1E1E;
}
.pagination .nbPageAndButton-paginationContainer .button-pagination {
  margin-right: 1.6rem;
  display: flex;
  border-top: 1px solid #1E1E1E;
  border-bottom: 1px solid #1E1E1E;
  border-radius: 0.5rem;
  align-items: center;
}
.pagination .nbPageAndButton-paginationContainer .button-pagination button {
  border: 0;
  cursor: pointer;
  flex-basis: 100% !important;
  border-radius: 0 !important;
  border-right: 1px solid #1E1E1E;
  color: #1E1E1E;
  height: 100%;
  font-weight: 500;
  transition: 0.3s !important;
}
.pagination .nbPageAndButton-paginationContainer .button-pagination button:hover {
  color: #fff;
  background-color: #1E1E1E;
}
.pagination .nbPageAndButton-paginationContainer .button-pagination button:last-child {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}
.pagination .nbPageAndButton-paginationContainer .button-pagination button:first-child {
  border-left: 1px solid #1E1E1E;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}
.pagination .nbPageAndButton-paginationContainer .button-pagination button:disabled {
  opacity: 1 !important;
}
.pagination .nbPageAndButton-paginationContainer .button-pagination button:disabled span {
  opacity: 0.5;
}
.pagination .nbPageAndButton-paginationContainer .button-pagination button:disabled:hover {
  color: #1E1E1E;
  background-color: #fff;
}

.tableTdContainer {
  display: flex;
  align-items: center;
}
.tableTdContainer .iconFileSvg {
  margin-right: 1.6rem;
}

.noBorderBottom {
  border-bottom: 0;
}

.borderBottom {
  border-bottom: 1px solid #ddd;
}
.borderBottom:last-child {
  border-bottom-right-radius: 0.5rem;
}

.nombreLigneParPage-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nbPageAndButton-paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nbPageAndButton-paginationContainer .nombrePage-pagination span {
  font-weight: 500;
}
.nbPageAndButton-paginationContainer .button-pagination {
  margin-right: 0.8rem;
}
.nbPageAndButton-paginationContainer .button-pagination .pagination-btn {
  font-size: 1.4rem;
  outline: none;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-color: #fff;
  border: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  touch-action: manipulation;
  line-height: 1.5714285714;
  min-height: 3.2rem;
  padding: 0.4rem 1.5rem;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  color: rgba(0, 0, 0, 0.88);
}
.nbPageAndButton-paginationContainer .button-pagination .pagination-btn.first {
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
}
.nbPageAndButton-paginationContainer .button-pagination .pagination-btn.second {
  border-right: 1px solid #d9d9d9;
}
.nbPageAndButton-paginationContainer .button-pagination .pagination-btn.last {
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}
.nbPageAndButton-paginationContainer .button-pagination .pagination-btn:hover {
  color: #fff;
  background-color: #2b2b2b;
}
.nbPageAndButton-paginationContainer .button-pagination .pagination-btn:active {
  color: #fff;
  background-color: #000000;
}
.nbPageAndButton-paginationContainer .button-pagination .pagination-btn.disabled {
  color: rgba(0, 0, 0, 0.25);
}
.nbPageAndButton-paginationContainer .button-pagination .pagination-btn.disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
}
.nbPageAndButton-paginationContainer .button-pagination .pagination-btn.disabled:active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.goToPage-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}

.releveAbsenceJour-container {
  padding-top: 0 !important;
}

.supprimerForm {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.1rem 0.3rem 0rem rgba(54, 74, 99, 0.05);
  position: relative;
  padding: 4.8rem;
}
.supprimerForm .modal-title {
  margin-bottom: 1.6rem;
}
.supprimerForm .modal-confirmation-text {
  margin-top: 1.6rem;
  margin-bottom: 4.8rem;
}
.supprimerForm .suppression-mot {
  font-weight: 700;
  color: #d8556b;
}
.supprimerForm .button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.supprimerForm .button-container .supprimer-form-annuler {
  margin-right: 4.8rem;
  width: 10rem;
  color: white !important;
  background-color: #d8556b;
  border-color: #d8556b;
}
.supprimerForm .button-container .supprimer-form-annuler:hover {
  background-color: #d13852;
}
.supprimerForm .button-container .supprimer-form-annuler:active {
  outline: 0.3rem solid #f3c9d0;
}
.supprimerForm .button-container .supprimer-form-valider {
  width: 10rem;
  color: white !important;
  background-color: #2fc451;
  border-color: #2fc451;
}
.supprimerForm .button-container .supprimer-form-valider:hover {
  background-color: #28a745;
}
.supprimerForm .button-container .supprimer-form-valider:active {
  outline: 0.3rem solid #d8f6df;
}

.modifier-form {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.1rem 0.3rem 0rem rgba(54, 74, 99, 0.05);
  position: relative;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
}
.modifier-form-label {
  padding-bottom: 0.4rem;
}
.modifier-form-input {
  margin-bottom: 0.8rem;
}
.modifier-form-input:disabled {
  cursor: not-allowed;
}
.modifier-form .validate-button-fw {
  margin-top: 4.8rem;
}
.modifier-form .modal-title {
  margin-bottom: 4.8rem;
  margin-right: 8rem;
}
.modifier-form .button-container {
  display: flex;
  justify-content: center;
}
.modifier-form .button-container .modifier-form-annuler {
  margin-right: 4.8rem;
  width: 10rem;
  color: white !important;
  background-color: #d8556b;
  border-color: #d8556b;
  margin-top: 4.8rem;
}
.modifier-form .button-container .modifier-form-annuler:hover {
  background-color: #d13852;
}
.modifier-form .button-container .modifier-form-annuler:active {
  outline: 0.3rem solid white;
}
.modifier-form .button-container .modifier-form-valider {
  width: 12rem;
  color: white !important;
  background-color: #2fc451;
  border-color: #2fc451;
  margin-top: 4.8rem;
}
.modifier-form .button-container .modifier-form-valider:hover {
  background-color: #28a745;
}
.modifier-form .button-container .modifier-form-valider:active {
  outline: 0.3rem solid #d8f6df;
}

.ajouter-form {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.1rem 0.3rem 0rem rgba(54, 74, 99, 0.05);
  position: relative;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
}
.ajouter-form .modal-title {
  margin-bottom: 4.8rem;
  margin-right: 8rem;
}
.ajouter-form-label {
  margin-bottom: 0.4rem;
}
.ajouter-form-input {
  margin-bottom: 0.8rem;
}
.ajouter-form .validate-button-fw {
  margin-top: 4.8rem;
}

.sm2 {
  margin-right: 0.8rem;
}

.hrBanque {
  margin-bottom: 1.6rem;
}

.default-label {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: #344357;
}

.dotMenu-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
}
.dotMenu-label svg {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  color: #1E1E1E;
}
.dotMenu-label svg path {
  color: #1E1E1E;
}

.dotMenuLabel {
  font-size: 1.4rem;
  margin-left: 0.8rem;
}

.label-and-input {
  width: 100%;
  margin-bottom: 2rem;
}
.label-and-input .new-default-input {
  margin-top: 0.8rem;
}
.label-and-input.horizontal {
  display: flex;
  align-items: center;
}
.label-and-input.horizontal label {
  width: 6rem;
}

.two-column-form {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.two-column-form .label-and-input {
  width: 48%;
  margin-bottom: 0;
}

.form-label {
  font-weight: 500;
  font-size: 1.6rem;
  white-space: nowrap;
}
.form-label .required-field {
  color: #d8556b;
}

.small-label {
  font-weight: 400;
  font-size: 1.5rem;
  white-space: nowrap;
}

.form-error-message {
  font-size: 1.2rem;
  color: #d8556b;
  margin-top: 0.4rem;
}

.materiel-info-label {
  margin-right: 0.4rem;
}

.default-input {
  padding: 1.6rem 1.6rem;
  height: 4rem;
  border-radius: 5px;
  font-size: 1.5rem;
  border: 1px solid #dbdfea;
  color: #3c4d62;
  font-weight: 400;
}
.default-input::-moz-placeholder {
  opacity: 0.4;
}
.default-input::placeholder {
  opacity: 0.4;
}

.textarea-input {
  font-family: Arial;
  font-weight: 700;
  padding-top: 0.8em;
}

.input-datePicker {
  flex-wrap: wrap;
}
.input-datePicker .react-date-picker__clear-button {
  padding: 0;
  margin-left: 0.4rem;
}
.input-datePicker .react-date-picker__wrapper {
  margin-bottom: 0.8rem;
  border-radius: 0.5rem;
  border: 1px solid #dbdfea;
  padding: 0 1.6rem;
  justify-content: center;
  flex-basis: 100%;
}
.input-datePicker .react-date-picker__wrapper input {
  font-size: 1.5rem;
  color: #3c4d62;
  font-weight: 700;
  margin-right: 0;
  width: auto;
  text-align: center;
}
.input-datePicker .react-date-picker__wrapper .react-date-picker__inputGroup__leadingZero, .input-datePicker .react-date-picker__wrapper .react-date-picker__inputGroup__divider {
  font-size: 1.5rem;
  color: #3c4d62;
  font-weight: 700;
}
.input-datePicker .react-date-picker__wrapper .react-date-picker__range-divider {
  padding: 0 2rem;
  font-size: 2.6rem;
  font-weight: 700;
}
.input-datePicker .react-date-picker__wrapper .react-date-picker__inputGroup {
  flex-grow: 0;
}
.input-datePicker .react-date-picker__wrapper .react-date-picker__inputGroup:last-child() {
  flex-grow: 1 !important;
}
.input-datePicker .react-date-picker__calendar {
  left: 50% !important;
  transform: translateX(-50%);
  position: absolute;
}

.react-datepicker-wrapper {
  width: 21.3rem !important;
  height: 100% !important;
}
.react-datepicker-wrapper input {
  height: inherit !important;
}

.react-time-picker {
  height: 4rem;
}
.react-time-picker .input-datetimePicker, .react-time-picker .react-time-picker__wrapper {
  height: 100%;
}

.input-datetimePicker {
  flex-wrap: wrap;
}
.input-datetimePicker .react-daterange-picker__clear-button {
  padding: 0;
  margin-left: 0.4rem;
}
.input-datetimePicker .react-timerange-picker__clear-button {
  padding: 0;
  margin-left: 0.4rem;
}
.input-datetimePicker .react-time-picker__clear-button {
  padding: 0;
  margin-left: 0.4rem;
}
.input-datetimePicker .react-daterange-picker__wrapper {
  border-radius: 0.5rem;
  border: 1px solid #dbdfea;
  padding: 0 1.6rem;
  justify-content: center;
  flex-basis: 100%;
}
.input-datetimePicker .react-daterange-picker__wrapper input {
  font-size: 1.5rem;
  color: #3c4d62;
  font-weight: 700;
  margin-right: 0;
  width: auto;
  text-align: center;
}
.input-datetimePicker .react-daterange-picker__wrapper .react-daterange-picker__inputGroup__leadingZero, .input-datetimePicker .react-daterange-picker__wrapper .react-date-picker__inputGroup__divider {
  font-size: 1.5rem;
  color: #3c4d62;
  font-weight: 700;
}
.input-datetimePicker .react-daterange-picker__wrapper .react-daterange-picker__range-divider {
  padding: 0 2rem;
  font-size: 2.6rem;
  font-weight: 700;
}
.input-datetimePicker .react-daterange-picker__wrapper .react-daterange-picker__inputGroup {
  flex-grow: 0;
}
.input-datetimePicker .react-daterange-picker__wrapper .react-daterange-picker__inputGroup:last-child() {
  flex-grow: 1 !important;
}
.input-datetimePicker .react-daterange-picker__calendar {
  left: 50% !important;
  transform: translateX(-50%);
}
.input-datetimePicker .react-timerange-picker__wrapper {
  border-radius: 0.5rem;
  border: 1px solid #dbdfea;
  padding: 0 1.6rem;
  justify-content: center;
  flex-basis: 100%;
}
.input-datetimePicker .react-timerange-picker__wrapper input {
  font-size: 1.5rem;
  color: #3c4d62;
  font-weight: 700;
  margin-right: 0;
  width: auto;
  text-align: center;
}
.input-datetimePicker .react-timerange-picker__wrapper .react-timerange-picker__inputGroup__leadingZero, .input-datetimePicker .react-timerange-picker__wrapper .react-time-picker__inputGroup__divider {
  font-size: 1.5rem;
  color: #3c4d62;
  font-weight: 700;
}
.input-datetimePicker .react-timerange-picker__wrapper .react-timerange-picker__range-divider {
  padding: 0 2rem;
  font-size: 2.6rem;
  font-weight: 700;
}
.input-datetimePicker .react-timerange-picker__wrapper .react-timerange-picker__inputGroup {
  flex-grow: 0;
}
.input-datetimePicker .react-timerange-picker__wrapper .react-timerange-picker__inputGroup:last-child() {
  flex-grow: 1 !important;
}
.input-datetimePicker .react-timerange-picker__calendar {
  left: 50% !important;
  transform: translateX(-50%);
}
.input-datetimePicker .react-time-picker__wrapper {
  border-radius: 0.5rem;
  border: 1px solid #dbdfea;
  padding: 0 1.6rem;
  justify-content: center;
  flex-basis: 100%;
}
.input-datetimePicker .react-time-picker__wrapper input {
  font-size: 1.5rem;
  color: #3c4d62;
  font-weight: 700;
  margin-right: 0;
  width: auto;
  text-align: center;
}
.input-datetimePicker .react-time-picker__wrapper .react-time-picker__inputGroup__leadingZero, .input-datetimePicker .react-time-picker__wrapper .react-time-picker__inputGroup__divider {
  font-size: 1.5rem;
  color: #3c4d62;
  font-weight: 700;
}
.input-datetimePicker .react-time-picker__wrapper .react-time-picker__range-divider {
  padding: 0 2rem;
  font-size: 2.6rem;
  font-weight: 700;
}
.input-datetimePicker .react-time-picker__wrapper .react-timerange-picker__inputGroup {
  flex-grow: 0;
}
.input-datetimePicker .react-time-picker__wrapper .react-timerange-picker__inputGroup:last-child() {
  flex-grow: 1 !important;
}
.input-datetimePicker .react-time-picker__wrapper .react-time-picker__inputGroup {
  text-align: center;
  flex-grow: 0;
}

.dropzone-icon {
  margin-bottom: 1.6rem;
  width: 7rem;
  height: 7rem;
}

.dropzone-text {
  font-size: 1.3rem;
}
.dropzone-text span {
  color: #1E1E1E;
}

.fileItem {
  display: flex;
  align-items: center;
}
.fileItem .deleteFileSvg {
  width: 1.7rem;
  height: 1.7rem;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.fileItem .deleteFileSvg:hover {
  color: #0a0a0a;
}
.fileItem .deleteFileSvg:active {
  outline: 0.3rem solid #9e9e9e;
}
.fileItem .iconFileSvg {
  width: 2.8rem;
  height: 2.8rem;
  margin-right: 2rem;
}
.fileItem p {
  font-size: 1.5rem;
  color: #1E1E1E;
  flex: 1;
}
.fileItem select {
  width: auto;
  flex: 1;
  margin-right: 0;
  margin-right: 2rem;
}
.fileItem .default-input {
  flex: 2;
  margin-right: 2rem;
}

.input-with-right-icon {
  position: relative;
}
.input-with-right-icon .right-content {
  cursor: pointer;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  margin-right: 2rem;
}

.number-noArrows::-webkit-outer-spin-button, .number-noArrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.number-noArrows[†ype=number] {
  -moz-appearance: textfield;
}

.custom-time-input {
  padding: 0 0.4rem !important;
  height: 4rem !important;
  font-size: 1.5rem !important;
  border-top: 1px solid #dbdfea !important;
  border-right: 1px solid #dbdfea !important;
  border-bottom: 1px solid #dbdfea !important;
  border-left: 1px solid #dbdfea !important;
  color: #3c4d62 !important;
  font-weight: 700 !important;
  text-align: center;
  margin-right: 0;
  border-radius: 0;
}
.custom-time-input::-webkit-outer-spin-button, .custom-time-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.custom-time-input[†ype=number] {
  -moz-appearance: textfield;
}
.custom-time-input::-moz-placeholder {
  opacity: 0.4 !important;
}
.custom-time-input::placeholder {
  opacity: 0.4 !important;
}
.custom-time-input.left {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-right: 0 !important;
  flex: 1 !important;
  text-align: right;
}
.custom-time-input.left:focus {
  border-right: 0 !important;
}
.custom-time-input.center {
  padding: 0 0.8rem !important;
  border-right: 0 !important;
  border-left: 0 !important;
  display: flex;
  align-items: center;
}
.custom-time-input.right {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-left: 0 !important;
  flex: 1 !important;
  text-align: left;
}

.singleDatePicker {
  min-height: 3.8rem;
}
.singleDatePicker .react-date-picker__wrapper {
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 0.6rem;
  margin-right: 0;
  background-color: #fff;
}
.singleDatePicker .react-date-picker__wrapper input {
  font-family: "DM Sans", sans-serif;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.88);
}
.singleDatePicker .react-date-picker__inputGroup__divider {
  font-family: "DM Sans", sans-serif;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.88);
}
.singleDatePicker .react-date-picker__inputGroup__leadingZero {
  font-family: "DM Sans", sans-serif;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.88);
}

.new-default-input {
  font-family: "DM Sans", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0.4rem 1.1rem;
  color: rgba(0, 0, 0, 0.88);
  font-size: 1.4rem;
  line-height: 1.5714285714;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100% !important;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 0.6rem;
  transition: all 0.2s;
  min-height: 3.8rem;
}
.new-default-input.noFullWidth {
  width: auto;
}
.new-default-input.input-error {
  border-color: #d8556b;
}
.new-default-input.input-error:hover {
  border-color: #d13852;
}
.new-default-input.input-error:focus {
  border-color: #d13852;
  box-shadow: 0 0 0 2px rgba(216, 85, 107, 0.64);
}
.new-default-input:hover {
  border-color: #2b2b2b;
  border-inline-end-width: 1px;
}
.new-default-input:focus {
  border-color: #2b2b2b;
  box-shadow: 0 0 0 2px rgba(3, 3, 3, 0.64);
  border-inline-end-width: 1px;
  outline: 0;
}
.new-default-input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.25);
  -moz-user-select: none;
       user-select: none;
}
.new-default-input::placeholder {
  color: rgba(0, 0, 0, 0.25);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.demandeTransport .search-input {
  width: 50%;
}

.search-input {
  padding: 0.4rem 1.1rem;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 0.6rem;
  cursor: text;
  transition: all 0.2s;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  min-height: 3.8rem;
  width: 50%;
}
.search-input:hover {
  border-color: #2b2b2b;
  border-inline-end-width: 1px;
}
.search-input.focus {
  border-color: #2b2b2b;
  box-shadow: 0 0 0 2px rgba(3, 3, 3, 0.64);
  border-inline-end-width: 1px;
  outline: 0;
}
.search-input input {
  margin: 0;
  border: 0;
  color: #1E1E1E;
  font-weight: 500;
  flex: 1;
}
.search-input input:focus {
  border: 0;
  outline: 0;
}
.search-input input::-moz-placeholder {
  color: rgba(111, 111, 111, 0.7176470588);
  -moz-user-select: none;
       user-select: none;
  font-weight: 500;
}
.search-input input::placeholder {
  color: rgba(111, 111, 111, 0.7176470588);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  font-weight: 500;
}
.search-input .searchIcon-container {
  width: 1.7rem;
  height: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.4rem;
}
.search-input .searchIcon-container svg {
  width: 100%;
  height: 100%;
  color: rgba(111, 111, 111, 0.7176470588);
}

.number-label-input-container {
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
  justify-content: space-between;
}
.number-label-input-container div {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}
.number-label-input-container div:last-child {
  margin-right: 0;
}
.number-label-input-container div input {
  margin-top: 0 !important;
  margin-right: 0.8rem;
}

.new-filter-label-input-container {
  display: flex;
  align-items: center;
}
.new-filter-label-input-container p {
  margin-right: 0.8rem;
}

.defaultFilter {
  min-width: 15rem;
}

.date-month-picker {
  margin-left: 0.8rem;
  flex: 1;
}
.date-month-picker .react-date-picker__wrapper {
  width: 100%;
  margin-right: 0;
  border: 1px solid #dbdfea;
  height: 36px;
  width: 100%;
  padding: 0;
}
.date-month-picker .react-date-picker__inputGroup {
  display: flex;
  padding: 0;
}
.date-month-picker .react-date-picker__inputGroup .react-date-picker__inputGroup__input.react-date-picker__inputGroup__month {
  flex: 1;
  padding: 0;
  text-align: center;
  text-align: center;
  font-size: 1.5rem;
  color: #1E1E1E;
  font-weight: 700;
}
.date-month-picker .react-date-picker__inputGroup__input.react-date-picker__inputGroup__year {
  flex: 1;
  text-align: center;
  font-size: 1.5rem;
  color: #1E1E1E;
  font-weight: 700;
}

.uploadFile-item {
  position: relative;
  cursor: pointer;
  margin-bottom: 2rem;
  border: 1px solid #d9d9d9;
  border-radius: 0.6rem;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 2rem;
}
.uploadFile-item:hover {
  border-color: #2b2b2b;
}
.uploadFile-item.selected {
  border-color: #2b2b2b;
}
.uploadFile-item:last-child {
  margin-bottom: 0;
}
.uploadFile-item .fileInfo {
  display: flex;
  align-items: center;
}
.uploadFile-item .fileInfo .fileIcon-container {
  flex: 0.1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.uploadFile-item .fileInfo .fileIcon-container .fileIcon {
  font-size: 3rem;
  margin-right: 2rem;
}
.uploadFile-item .fileInfo .fileInfo-content {
  flex: 1;
  margin-right: 2rem;
}
.uploadFile-item .fileInfo .fileInfo-content .fileName {
  font-weight: 600;
}
.uploadFile-item .fileInfo .fileInfo-content input {
  margin-bottom: 0.8rem;
}
.uploadFile-item .fileInfo .fileInfo-content input.input-error {
  margin-bottom: 0;
}
.uploadFile-item .fileInfo .fileInfo-content .fileSize {
  font-weight: 200;
}
.uploadFile-item .progressBarFileUpload-container {
  margin-top: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploadFile-item .progressBarFileUpload-container .progressBarFileUpload {
  flex: 1;
}
.uploadFile-item .deleteIconContainer {
  position: absolute;
  right: 1.6rem;
  top: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploadFile-item .deleteIconContainer .deleteIcon {
  font-size: 1.8rem;
  cursor: pointer;
}
.uploadFile-item .visibleBy {
  margin-top: 0.8rem;
}

.dotAndText {
  display: flex;
  align-items: center;
}
.dotAndText .dot {
  width: 2.4rem;
  height: 2.4rem;
}
.dotAndText .danger {
  width: 2rem;
  height: 2rem;
}
.dotAndText .greenDot {
  color: #28a745;
}
.dotAndText .yellowDot {
  color: #d8a72a;
}
.dotAndText .redDot {
  color: #d13852;
}
.dotAndText .verrou {
  width: 2rem;
  height: 2rem;
}

.select-search {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  height: 4rem;
  font-size: 1.5rem;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

.select-search__value {
  position: relative;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

.select-search__input {
  display: block;
  border-radius: 3px;
  outline: none;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 0.4rem 1.6rem;
  height: 4rem;
  margin-bottom: 0.8rem;
  border-radius: 5px;
  font-size: 1.5rem;
  border: 1px solid #dbdfea;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

.select-search__options {
  list-style: none;
  padding: 0;
}

.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2fcc8b;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

.select-search.is-disabled {
  opacity: 0.5;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

.semaine-selection {
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 20rem;
  height: 4rem;
}
.semaine-selection .currentWeek {
  flex: 1;
  text-align: center;
  font-size: 1.5rem;
  border-top: 0.1rem solid black;
  border-bottom: 0.1rem solid black;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formSelect-search-container {
  font-size: 1.4rem;
}
.formSelect-search__control {
  cursor: pointer !important;
  min-height: 3.8rem;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 0.6rem;
  transition: all 0.2s;
}
.formSelect-search__control:hover {
  border-color: #2b2b2b !important;
}
.formSelect-search__placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.formSelect-search__input {
  color: rgba(0, 0, 0, 0.88) !important;
}
.formSelect-search__single-value {
  color: rgba(0, 0, 0, 0.88) !important;
  font-weight: 500 !important;
}
.formSelect-search-container-full {
  flex: 1;
}

.formSelect-statut-semaine-filter {
  width: 218px;
}

.formSelect-annee-filter {
  width: 218px;
}

.formSelect-numero-semaine-filter {
  width: 290px;
}

.formSelect-chef-chantier-filter {
  width: 290px;
}

.formSelect-salarie-present-filter {
  width: 370px;
}

.icon {
  width: 2.4rem;
  height: 2.4rem;
}

.iconFileSvg {
  width: 3rem;
  height: 3rem;
}

.eyeDocumentSvg {
  width: 2.4rem;
  height: 2.4rem;
}

.telechargerDocumentSvg {
  width: 2.4rem;
  height: 2.4rem;
}

.supprimerDocumentSvg {
  width: 2.4rem;
  height: 2.4rem;
}

.excelIcon {
  color: #28a745;
}

.csvIcon {
  color: #28a745;
}

.iconYellow {
  color: #d8a72a;
}

.iconRed {
  color: #d13852;
}

.iconGreen {
  color: #28a745;
}

.section {
  margin-right: 4.8rem;
  margin-left: 4.8rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container-basic {
  background-color: #fff;
  padding: 1.6rem;
  border-radius: 0.6rem;
  border: 1px solid #f0f0f0;
  box-shadow: 0 1px 2px 0 rgba(25, 15, 15, 0.07);
}
.container-basic.small-padding {
  padding: 0.8rem 2rem;
}
.container-basic.transparent {
  background-color: #f6f6f6;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 1.6rem 0;
}
.container-basic.no-border-radius-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.container-basic.no-border-bottom {
  border-bottom: 0;
}
.container-basic.no-border-radius-top {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.container-basic.no-border-top {
  border-top: 0;
}
.container-basic.filter {
  max-height: 3.8rem;
}

.main-content {
  background-color: #fff;
  border-radius: 0.6rem;
  border: 1px solid #f0f0f0;
}
.main-content.noBorder {
  border-radius: 0;
  box-shadow: none;
}
.main-content.mbb {
  margin-bottom: 4.8rem;
}

.search-filter-content {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.search-filter-content .search-filter-section {
  flex: 0.5;
}
.search-filter-content .search-filter-section p {
  font-weight: 500;
  font-size: 1.6rem;
  margin-bottom: 0.4rem;
  white-space: nowrap;
}
.search-filter-content .search-filter-section:first-child {
  flex: 1;
}
.search-filter-content .search-filter-section .formSelect-search-container {
  height: 3.2rem;
}
.search-filter-content .search-filter-section .formSelect-search-container .formSelect-search__control {
  height: 3.2rem;
  min-height: 3.2rem !important;
}
.search-filter-content button {
  align-self: flex-end;
}

.formSelect-search-container.fixZIndex .formSelect-search__menu {
  z-index: 10;
}

.pageTitle-container {
  background-color: #fff;
  padding: 1.6rem 0;
  border-radius: 0.6rem;
  border: 1px solid #f0f0f0;
  box-shadow: 0 1px 2px 0 rgba(25, 15, 15, 0.07);
}
.pageTitle-container.transparent {
  background-color: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
}

.search-filter-container {
  background-color: #fff;
  padding: 1.6rem;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  border: 1px solid #f0f0f0;
  box-shadow: 0 1px 2px 0 rgba(25, 15, 15, 0.07);
}

.new-filter-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 1.6rem;
}
.new-filter-section button {
  margin-right: 3.2rem;
}

.filterContainer {
  display: flex;
  row-gap: 1.2rem;
  -moz-column-gap: 2.4rem;
       column-gap: 2.4rem;
  margin-bottom: 1.2rem;
  flex-wrap: wrap;
}
.filterContainer .new-default-input {
  width: auto !important;
}

.new-filter-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  row-gap: 1.2rem;
  -moz-column-gap: 2.4rem;
       column-gap: 2.4rem;
  flex-wrap: wrap;
}

.table-container {
  background-color: #fff;
  border-radius: 0;
  border: 1px solid #f0f0f0;
  border-bottom: 0;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 1.6rem;
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
  border: 1px solid #f0f0f0;
  box-shadow: 0 1px 2px 0 rgba(25, 15, 15, 0.07);
  border-top: 0;
}

.profil-salarie-container {
  display: flex;
  gap: 3.2rem;
}
.profil-salarie-container .salarie-info {
  flex: 3;
}
.profil-salarie-container .salarie-stats {
  flex: 1.5;
}

.edit-actualite-container {
  display: flex;
  gap: 2.4rem;
}
.edit-actualite-container .actualite-content {
  flex: 3;
}
.edit-actualite-container .actualite-info {
  max-width: 300px;
  flex: 1.5;
}

.setings-container {
  background-color: #fff;
  padding: 1.6rem;
  border-radius: 0.6rem;
  border: 1px solid #f0f0f0;
  box-shadow: 0 1px 2px 0 rgba(25, 15, 15, 0.07);
}
.setings-container.small-padding {
  padding: 0.8rem 2rem;
}
.setings-container.transparent {
  background-color: #f6f6f6;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 1.6rem 0;
}
.setings-container.no-border-radius-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.setings-container.no-border-bottom {
  border-bottom: 0;
}
.setings-container.no-border-radius-top {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.setings-container.no-border-top {
  border-top: 0;
}
.setings-container.filter {
  max-height: 3.8rem;
}
.setings-container .settings-top-section {
  margin-bottom: 1.6rem;
}
.setings-container .settings-main-section .options-container {
  margin-bottom: 1.6rem;
}
.setings-container .settings-main-section .options-container:last-child {
  margin-bottom: 0;
}

.pageContent-nav {
  display: flex;
}
.pageContent-nav li:first-child {
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
  border-right: 0;
}
.pageContent-nav li:last-child {
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
  border-left: 0;
}
.pageContent-nav .link-selected {
  background-color: #1E1E1E;
  color: #fff;
}
.pageContent-nav .link {
  background-color: #fff;
  border-color: #d9d9d9;
}

.insideNavigationContainer {
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
}
.insideNavigationContainer ul {
  display: flex;
  height: 100%;
}
.insideNavigationContainer ul li {
  cursor: pointer;
  padding: 0.8rem 0.4rem;
  margin: 0 0.8rem;
}
.insideNavigationContainer ul li p {
  font-weight: 500;
  opacity: 0.4;
  transition: all 0.2s ease-in-out;
}
.insideNavigationContainer ul li:first-child {
  margin-left: 0;
}
.insideNavigationContainer ul li:last-child {
  margin-right: 0;
}
.insideNavigationContainer ul li.active {
  margin-bottom: -0.2rem;
  border-radius: 0.1rem;
  border-bottom: 3px solid #d8556b;
}
.insideNavigationContainer ul li.active p {
  opacity: 1;
  color: #d8556b;
}
.insideNavigationContainer ul li:hover p {
  opacity: 1;
  color: #d8556b;
}

.breadcrumb-link p {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.breadcrumb-link:hover p {
  color: #d8556b;
  opacity: 1 !important;
}

#applicationGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 10rem);
  grid-gap: 2.4rem;
}
#applicationGrid .appIcon {
  background-color: #fff;
  border-radius: 0.6rem;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease;
}
#applicationGrid .appIcon:hover {
  color: #fff;
  background-color: #d8556b;
}
#applicationGrid .appIcon .iconContainer {
  flex: 1;
  padding: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
#applicationGrid .appIcon .iconContainer svg {
  width: 80%;
  height: 80%;
}

.downloadAppContainer {
  background-color: #fff;
  border-radius: 0.6rem;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  transition: all 0.25s ease;
  padding: 2.4rem 2rem;
  display: flex;
  align-items: center;
  color: #1E1E1E;
  max-width: 28rem;
  width: 100%;
  height: 10rem;
}
.downloadAppContainer:hover {
  color: #fff;
  background-color: #d8556b;
}
.downloadAppContainer .downloadApp-infoContainer {
  display: flex;
  align-items: center;
}
.downloadAppContainer .downloadApp-infoText {
  text-align: center;
  margin-left: 1.6rem;
}
.downloadAppContainer svg {
  width: 5rem;
  height: 5rem;
}

.demandeTransportItem {
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 1rem;
  min-height: 10rem;
  max-width: 25rem;
  min-width: 25rem;
  padding: 0.8rem 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.demandeTransportItem:last-child {
  margin-right: 0;
}
.demandeTransportItem .codeDemande {
  font-weight: 600;
  font-family: "DM Sans", sans-serif;
  font-size: 1.6rem;
}
.demandeTransportItem .utilisateurDemande {
  font-weight: 300;
  font-size: 1.2rem;
}
.demandeTransportItem .btn {
  font-size: 1.6rem;
  padding: 0 0.8rem;
  min-height: 0;
}

.transportEtat {
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  top: 2px;
  left: 2px;
  -webkit-clip-path: polygon(100% 0, 0 0, 0 100%);
          clip-path: polygon(100% 0, 0 0, 0 100%);
}

.transportItem {
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  min-height: 10rem;
  max-width: 25rem;
  min-width: 25rem;
  padding: 0.8rem 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.transportItem:last-child {
  margin-right: 0;
}
.transportItem .codeDemande {
  font-weight: 600;
  font-family: "DM Sans", sans-serif;
  font-size: 1.6rem;
}
.transportItem .utilisateurDemande {
  font-weight: 300;
  font-size: 1.2rem;
}
.transportItem .btn {
  font-size: 1.6rem;
  padding: 0 0.8rem;
  min-height: 0;
}

.demandeTransportPadding {
  padding-right: 0.8rem;
}

.demandeTransportContainer-horizontal > div:first-child {
  overflow-x: scroll;
  display: flex;
  flex-wrap: nowrap;
  min-height: 10rem;
}

.small_sidebar .demandeTransportContainer-horizontal > div:first-child {
  max-width: calc(100vw - 7rem - 9.6rem);
}
.small_sidebar .chauffeurTransportSection {
  max-width: calc(100vw - 7rem - 9.6rem);
}
.small_sidebar.openHover .demandeTransportContainer-horizontal > div:first-child {
  max-width: calc(100vw - 29rem - 9.6rem);
}
.small_sidebar.openHover .demandeTransportContainer-horizontal .chauffeurTransportSection {
  max-width: calc(100vw - 29rem - 9.6rem);
}

.openHover .demandeTransportContainer-horizontal > div:first-child {
  max-width: calc(100vw - 29rem - 9.6rem);
}
.openHover .chauffeurTransportSection {
  max-width: calc(100vw - 29rem - 9.6rem);
}

.demandeTransportContainer-horizontal > div:first-child {
  max-width: calc(100vw - 29rem - 9.6rem);
  transition: all 450ms ease;
}

.chauffeurDroppableContainer > div:nth-child(2) {
  flex: 1;
  overflow-y: auto;
}

.os-content > div {
  height: 100%;
}

.chauffeurTransportSection {
  max-width: calc(100vw - 29rem - 9.6rem);
}

.chauffeurTransportHeader {
  padding: 1.6rem;
}

.titleHeader {
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 0.8rem;
}

.chauffeurTransportName-edit {
  transition: all 0.2s ease-in-out;
}
.chauffeurTransportName-edit:hover {
  color: #d8556b;
  cursor: pointer;
}

.editProfile-picture-container svg {
  width: 15rem;
  height: 15rem;
}

@media (max-width: 1150px) {
  .react-datepicker-wrapper {
    width: 18.5rem !important;
  }
  .logout_button, .dashboard-header-chef .middle-header-chef .chef-button-nav {
    margin-right: 0;
    border-right: 1px solid #D9DCDD;
    font-size: 1.2rem;
    padding: 1rem;
    width: 12rem;
  }
  .dashboard-header-userAccount-container .userIcon {
    height: 2rem;
    width: 2rem;
  }
  .dashboard-header-userAccount-container .username-role-container .userRole {
    font-size: 0.8rem;
    line-height: 0.8rem;
  }
  .dashboard-header-userAccount-container .username-role-container .userIdentity h3 {
    font-size: 1rem;
    line-height: 0.6rem;
  }
  .sidebar {
    width: 25rem;
  }
  .sidebar .sidebar__main-nested-link, .sidebar .sidebar__main-linkItem {
    font-size: 1.4rem;
  }
  .demandeTransportContainer-horizontal > div:first-child {
    max-width: calc(100vw - 25rem - 9.6rem);
  }
  .chauffeurTransportSection {
    max-width: calc(100vw - 25rem - 9.6rem);
  }
  .dashboard-layout-main-container {
    margin-left: 25rem;
  }
  .dashboard-header {
    width: calc(100% - 25rem);
  }
  .tableau-top-section {
    flex-wrap: wrap;
  }
  .tableau-top-section h3 {
    display: inline-block;
    width: 100%;
    position: relative;
    flex: none;
    padding: 10px 0;
  }
  td, th {
    padding: 0.5rem;
    font-size: 1.3rem;
  }
  input, select, .default-button {
    font-size: 1.2rem;
  }
  .modal .modal-content {
    width: 70%;
  }
  .modal .new-modal {
    width: 70%;
  }
  .profil-salarie-container {
    flex-direction: column;
  }
}
@media (max-width: 860px) {
  .logout_button, .dashboard-header-chef .middle-header-chef .chef-button-nav {
    margin-right: 0;
    border-right: 1px solid #D9DCDD;
    font-size: 1rem;
    padding: 1rem;
    width: 10rem;
  }
  .sidebar__main-nested-link {
    padding-left: 2rem;
  }
  .sidebar__main-linkItem {
    justify-content: center;
  }
  .sidebar__main-linkItem .link-icon {
    margin-right: 0;
  }
  .sidebar__main-linkItem-text {
    display: none;
  }
  .sidebar__main-linkItem .chevron-right {
    display: none;
  }
  .sidebar {
    width: 7rem;
  }
  .demandeTransportContainer-horizontal > div:first-child {
    max-width: calc(100vw - 7rem - 9.6rem);
  }
  .subMenu-linklist {
    display: none;
  }
  .dashboard-layout-main-container {
    margin-left: 7rem;
  }
  .chauffeurTransportSection {
    max-width: calc(100vw - 7rem - 9.6rem);
  }
  .dashboard-header {
    width: calc(100% - 7rem);
  }
  .sidebar__main-linkContainer {
    padding: 0.8rem 0.8rem;
  }
  .sidebar__main-linkContainer:first-child {
    padding-top: 0.8rem;
  }
  .sidebar__main-linkContainer:last-child {
    padding-bottom: 0;
  }
  .sidebar__separator {
    margin: 0 0.8rem;
  }
  .modal .modal-content {
    width: 100%;
    border-radius: 0;
  }
  .modal .new-modal {
    width: 100%;
    border-radius: 0;
  }
  .profil-salarie-container, .edit-actualite-container {
    flex-direction: column;
  }
}
h1 {
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3.1rem;
}

.h1-blue-dark {
  color: #1E1E1E;
}

.modal-title {
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 3.2rem;
  color: #1E1E1E;
}

.modal-confirmation-text {
  font-size: 1.6rem;
  font-weight: 400;
  color: rgba(30, 30, 30, 0.8);
}

hr {
  height: 0.1rem;
  padding: 0;
  color: #344357;
  background-color: #344357;
  border: 0;
}

.login-error-message {
  margin-bottom: 1.6rem;
  color: #d13852;
  font-size: 1.3rem;
}

.h2-light {
  font-size: 1.8rem;
  font-weight: 500;
  color: rgba(30, 30, 30, 0.8);
}

.banqueHeure-Total {
  font-size: 1.8rem !important;
  font-weight: 500 !important;
  color: rgba(30, 30, 30, 0.8) !important;
}

.totalHeure-negatif {
  font-weight: 700;
  font-size: 2rem;
  color: #d13852;
}

.totalHeure-positif {
  font-weight: 700;
  font-size: 2rem;
  color: #28a745;
}

.totalHeure-nul {
  font-weight: 700;
  font-size: 2rem;
  color: #d8a72a;
}

.tableAbsence-span-positif {
  font-weight: 500;
  color: #28a745;
}

.tableAbsence-span-negatif {
  font-weight: 500;
  color: #d13852;
}

.tableAbsence-span-nul {
  font-weight: 500;
  color: #d8a72a;
}

.section-title {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: #344357;
}

.small-title {
  font-size: 1.5rem;
  color: #1E1E1E;
  font-weight: bold;
}

.small-text {
  font-size: 1.4rem;
  color: #1E1E1E;
}

.searchNumberTitle {
  color: rgba(30, 30, 30, 0.8);
  font-size: 1.3rem;
  font-weight: 400;
}

.section-vacation-title {
  font-size: 2rem;
  color: #1E1E1E;
}

.vacationsSalarieTitle {
  font-weight: 700;
  color: #1E1E1E;
  font-size: 1.8rem;
}

.vacationNumber {
  font-weight: 700;
  color: #1E1E1E;
  font-size: 1.6rem;
}

.vacationIndex {
  font-weight: 300;
  color: #1E1E1E;
  font-size: 1.6rem;
}

p {
  font-size: 1.4rem;
  line-height: 1.5714285714;
}

.fontSizeSM {
  font-size: 1.2rem;
}

.fontSizeLG {
  font-size: 1.6rem;
}

.fontSizeXL {
  font-size: 2rem;
}

h1 {
  font-size: 3.8rem;
  line-height: 1.2105263158;
}

h2 {
  font-size: 3rem;
  line-height: 1.2666666667;
}

h3 {
  font-size: 2.4rem;
  line-height: 1.3333333333;
}

h4 {
  font-size: 2rem;
  line-height: 1.4;
}

h5 {
  font-size: 1.6rem;
  line-height: 1.5;
}

.lineHeightSM {
  line-height: 1.6666666667;
}

.lineHeightLG {
  line-height: 1.5;
}

.transport_label_succes {
  color: #2fc451;
}

.transport_label_error {
  color: #d13852;
}