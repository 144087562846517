@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

* {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

body {
  font-family: "DM Sans", sans-serif;
  background-color: #f6f6f6;
}